import * as React from 'react';
import {Auth, Provider, Database} from './Firebase';
// import { FullStoryAPI } from "react-fullstory";
import Utils from './Utils.js';

class Profile extends React.Component {
	constructor() {
		super();

		this.state = {
			currentItem: '',
			username: '',
			items: [],
			location: null,
			userData: null
		}

		this.goToAvatarCreation = this.goToAvatarCreation.bind(this); // <-- add this line
		this.login = this.login.bind(this); // <-- add this line
		this.logout = this.logout.bind(this); // <-- add this line
	}

	componentDidMount() {
		Auth.onAuthStateChanged((user) => {
			if (user) {
				console.log("logging in " + user.displayName, user.uid)
				this._updateUserData(user);

				// this.identifyUser(user)
			} else {
				console.log('logging out', user)
				this.props.history.push('/login')
			}
		});
	}

	componentDidUpdate(prevProps, prevState) {
	}

	render() {
		return (
			<div
				className={this.props.show ? "z2 scaleIn isolation" : "scaleOut isolation"}
			>

				{this.state.userData ?
					<div className="flex items-center">
						{this.props.showLogout ?
							<button
								className="p0 pr2 right white scaleIn"
								onClick={this.logout}
								style={{
									height: 48
								}}
							>
								Logout
							</button>
						: null
						}

						<button
							className={`circle shadowLarge p0 hover isolation ${Utils.isNight() ? null : "invert" }`}
							onClick={this.goToAvatarCreation}
							style={{
								width: 48,
								height: 48
							}}
						>
							<img
								className="circle full"
								alt={this.state.userData.displayName}
								src={Utils.resizeUserPhoto(this.state.userData.photoURL)}
							/>
						</button>
					</div>
					:
					<button
						className="circle shadowLarge bg-white p0"
						onClick={this.login}
						style={{
							width: 48,
							height: 48
						}}
					>
					</button>
				}
			</div>

		);
	}

	// identifyUser(user) {
	// 	// Identify fullstory method
	// 	FullStoryAPI("identify", user.uid, {
	// 		displayName: user.displayName,
	// 		email: user.email
	// 	});
	// }

	_updateUserData = (user) => {
		const userData = {
			uid: user.uid,
			displayName: user.displayName,
			email: user.email,
			photoURL: user.photoURL,
			lastSeen: new Date()
		}

		localStorage.setItem("currentUser", JSON.stringify(userData));

		// Create a reference to the doc.
		let docRef = Database.collection("Users").doc(userData.uid);

		Database.runTransaction( (transaction) => {
		    // This code may get re-run multiple times if there are conflicts.
		    return transaction.get(docRef).then( (doc) => {
		    	let avatarCode = null;

				if (doc.exists) {
					avatarCode = doc.data().avatarCode;
					// console.log('avatar', avatarCode, doc.data())
		        }

				transaction.set(docRef, {
					uid: userData.uid,
					displayName: userData.displayName,
					email: userData.email,
					photoURL: userData.photoURL,
					lastSeen: userData.lastSeen
				}, {
					merge: true
				});

				return avatarCode;
			});
		}).then( (avatarCode) => {
			if (!avatarCode) {
				console.log("no avatarcode", avatarCode)
				this.goToPage('avatar');
			}
		}).catch( (error) => {
		    console.log("Transaction failed: ", error);
		});

		this.setState({ userData });

	}

	goToPage = (url) => {
		this.props.history.push(`/${url}`)
	}

	goToAvatarCreation = () => {
		console.log('somehow sending someone here')
		this.props.history.push('/avatar')
	}

	logout() {
		Auth.signOut()
			.then(() => {

				const userData = null;

				localStorage.removeItem('currentUser');
				this.setState({ userData });
			});
	}

	login() {
		Auth.signInWithRedirect(Provider)
			.then((result) => {
				const userData = result.user;

				this.setState({userData});
			});
	}
}

export default Profile;
import * as React from 'react';
import VolumeMeter from "./VolumeMeter.js";
import Utils from "./Utils.js";

class VideoParticipant extends React.Component {
	constructor(props) {
		super(props);

		this.rafID = null;
		this.meter = null;
		this.volumeInterval = null;

		this.state = {
			speakingVolume: false
		}
	}
	componentDidMount() {
		this._playStream(this.props.stream);
		// console.log("in the png", this.props.displayName)
	}

	componentWillUnmount() {
		if (this.meter) {
			this.meter.shutdown();
		}

		if (this.volumeInterval) {
			clearInterval(this.volumeInterval)
		}
	}
	componentDidUpdate(prevProps, prevState) {
		if (this.state.speakingVolume !== prevState.speakingVolume) {
			this.props.parentSpeakingMethod(this.props.id, this.state.speakingVolume);
		}

		if (this.props.stream !== prevProps.stream && this.props.stream) {
			console.log('video stream changed', this.props.email, this.props.stream )
			this._playStream(this.props.stream);
		}

	}

	render() {
		return (
			<div
				className="flex flex-column justify-center items-center relative participantContainer shadowLarge"
				title={`${this.props.displayName} ${this.props.email}`}
			>
				<video
					id={this.props.id}
					ref={video => {this.video = video}}
					className="participant mx-auto pointer mx1 my0"
					autoPlay
					playsInline
					style={{
						backgroundColor: Utils.randomColor()
					}}
					// srcobject={this.props.stream}
					// onClick={this._handleClick.bind(this)}
				/>

				<i className="white absolute material-icons flipper">{this.props.stream ? "" : "hourglass_empty"}</i>
				<p className="white absolute center uppercase bold">
					{this.props.stream ? this.props.displayName.charAt(0) : ""}
				</p>
			</div>
		);
	}

	_handleClick() {
		this.props.onClick(this.props.id);
	}

	_playStream(stream) {
		if (stream) {
			// this needs to be camel cased
			this.video.srcObject = stream;
			this._getVolume(stream);
		}
	}

	_getVolume(stream) {
		// Create an AudioNode from the stream
		// this line fixes Safari and old versions of Chrome
		let AudioContext = window.AudioContext || window.webkitAudioContext || false;

		console.log("getting audio", AudioContext)
		if (AudioContext) {
			let audioContext = new AudioContext();

			let mediaStreamSource = audioContext.createMediaStreamSource(stream);

			// Create a new volume meter and connect it.
			this.meter = VolumeMeter.createAudioMeter(audioContext);
			mediaStreamSource.connect(this.meter);

			this.volumeInterval = setInterval( () => {
				// console.log("volume check", this.props.id, this.meter.checkVolume());

				if (this.meter.checkVolume() > .05) {
					// assume this person is speaking
					this.setState({speakingVolume: this.meter.checkVolume()})
				} else {
					// assume isn't speaking, and only set if isn't set
					if (this.state.speakingVolume !== 0) {
						this.setState({speakingVolume: 0})
					}
				}
			}, 500);

			// kick off the visual updating
			// if (this.meter) {
				// this._drawLoop();
			// }
		} else {
			// Web Audio API is not supported
			console.warn("Web Audio API is not supported by browser");
		}

	}

	_drawLoop( time ) {
		if (this.meter) {
			console.log("meter", this.meter.volume);
		}

		// var WIDTH=500;
		// var HEIGHT=50;

		// const canvasContext = this.canvasMeter.getContext('2d');

		// // clear the background
		// canvasContext.clearRect(0,0,WIDTH,HEIGHT);

		// // check if we're currently clipping
		// if (this.meter.checkClipping())
		//     canvasContext.fillStyle = "red";
		// else
		//     canvasContext.fillStyle = "green";

		// // draw a bar based on the current volume
		// canvasContext.fillRect(0, 0, this.meter.volume*WIDTH*1.4, HEIGHT);

		// set up the next visual callback
		this.rafID = window.requestAnimationFrame( this._drawLoop );
	}
}

VideoParticipant.defaultProps = {
	show: true
};

export default VideoParticipant;
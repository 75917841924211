import React from 'react';
import { withRouter } from "react-router-dom";
import API from './components/API.js';
import Utils from './components/Utils.js';

// components
import Scene from './components/Scene.js';
import Profile from './components/Profile.js';
import AvatarCreation from './components/AvatarCreation.js';
import Selfie from './components/Selfie.js';

import ChatView from './components/ChatView.js';

import BackButton from './components/BackButton.js';

class Home extends React.Component {

	constructor(props)
	{
		super(props);

		this.state = {
			// showDebug: Utils.isMobile() ? false : true,
			showDebug: Utils.isLocalhost(),
			filteredPeople: {},
			rearFacingCamera: false,
			showRoster: false,
			showBackground: true,
			roster: {},
			title: {
				name: null,
				animation: null
			},
			selfie: null,
			allowMovement: null,
			status: {
				message: null,
				animation: null
			}
		};
	}

	componentDidMount() {
		// get roster
		API.getUsers(0, userData => {
			// console.log("roster", userData);
			// todo: need to handle if avatarCode isn't there liek above
			let roster = {};
			Object.entries(userData).slice(0, 4).map(entry => roster[entry] = entry[1]);

			this.setState({roster});

			if (this.props.match.params.type === "scrum") {
				this._filterPeople(userData)
			}
		});

		this._updateRoom();
	}

	componentDidUpdate(prevProps, prevStates) {
		if (this.props.match.params.type !== prevProps.match.params.type) {
			console.log('type change')
			this._updateRoom();
			this._filterPeople();
		}

		if (this.props.match.params.id !== prevProps.match.params.id) {
			console.log('id change')
			this._updateRoom();

			if (this.props.match.params.type === "chat") {
				console.log('we in chat')
				// API.getUsers(4, userData => {
				// 	// todo: need to handle if avatarCode isn't there liek above
				// 	this.setState({
				// 		people: userData
				// 	});
				// });
			}
		}
	}

	componentWillUnmount() {
		// unmount geoposition watch
		console.log("home unmounting");
		this.refs.scene.stop();
	}

	render() {

		return (
			<div>
				<div
					id="scene"
					className="full-viewport absolute"
				>
					<Scene
						ref="scene"
						showDebug={this.state.showDebug}
						match = {this.props.match}
						people={this.state.filteredPeople}
						parentStatusMethod={this._updateStatus}
					/>
				</div>

				<div className="nav z2 exclusion">
					<div className="flex justify-between items-center gutters">
						<BackButton
							show = {this.props.match.params.type ? true : false}
							onClick = {this._resetState}
						/>

						<div className="absolute centerX mt5 center">
							<h4 id="pageTitle" className={`mb0 ${this.state.title.animation}`}>{this.state.title.name}</h4>
							<p className={`pt1 ${this.state.status.animation}`}>{this.state.status.message}</p>
						</div>
						<div className="flex">
							<button
								className={`hover white mr2 ${!this.props.match.params.type ? "scaleIn" : "scaleOut"}`}
								onClick={() => {
									this._toggleRoster();
								}}
							>
								<i className={`material-icons scaleIn`}>person_add</i>
							</button>
							<Profile
								show = {true}
								showLogout = {this.props.match.params.type === "avatar" ? true : false}

								history={this.props.history}
							/>
						</div>
					</div>
				</div>

				<div
					className="z2 absolute flex flex-column white exclusion right-0 bottom-0 gutters"
				>
					<button
						className={`hover white p2`}
						onClick={ () => {
							this._toggleDebug()
						}}
					>
						<i className={`material-icons scaleIn`}>{ this.state.showDebug ? "signal_cellular_4_bar" : "signal_cellular_null" }</i>
					</button>

					{ Object.keys(this.state.filteredPeople).length > 1 ?
						<button
							className="hover white p2"
							onClick={() => {
								this.refs.scene.goToNextSpeaker();
							}}
						>
							<i className={`material-icons scaleIn`}>skip_next</i>
						</button>
					: null
					}

					{
						Utils.isMobile() ?
							<button
								className={`hover white p2`}
								onClick={ () => {
									this.refs.scene.toggleOrientation();
									this.setState({ rearFacingCamera: true })
								}}
							>
								<i className={`material-icons scaleIn`}>screen_rotation</i>
							</button>
						: null
					}

					<button
						className={`hover white p2`}
						onClick={ () => {
							this.refs.scene.toggleBackground();
							this.setState({showBackground: !this.state.showBackground})
						}}
					>
						<i className={`material-icons scaleIn`}>{this.state.showBackground ? "visibility" : "visibility_off"}</i>
					</button>

					<button
						className={`hover white p2`}
						onClick={ () => {
							this.refs.scene.goToIntro();
						}}
					>
						<i className={`material-icons scaleIn`}>outlined_flag</i>
					</button>

					<button
						className={`hover white p2`}
						onClick={ () => {
							if (this.state.allowMovement) {
								this.refs.scene.toggleMovement(false);
								this.setState({allowMovement: false})
							} else {
								this.refs.scene.toggleMovement(true);
								this.setState({allowMovement: true})
							}
						}}
					>
						<i className={`material-icons scaleIn`}>360</i>
					</button>

					<button
						className={`hover white p2`}
						onClick={ () => {
							this.refs.scene.goToPortrait();

							API.getCurrentUser(currentUserData => {
								let newPeople = {};
								newPeople[currentUserData.uid] = currentUserData;

								this._filterPeople(newPeople);
							});

							this._goToPage('selfie')
						}}
					>
						<i className={`material-icons scaleIn`}>center_focus_weak</i>
					</button>

				</div>

				<Selfie
					id = {this.props.match.params.id}
					people={this.state.filteredPeople}
					showDebug={this.state.showDebug}
					rearFacingCamera= {this.state.rearFacingCamera}
					sceneRef={this.refs.scene}
					parentStatusMethod={this._updateStatus}
					parentUserMethod={this._filterPeople}
				/>

				{

					this.props.match.params.type === "avatar" ?
						<AvatarCreation
							id = {this.props.match.params.id}
							people={this.state.filteredPeople}
							parentStatusMethod={this._updateStatus}
							parentUserMethod={this._filterPeople}
						/>
					:
					this.props.match.params.type === "scrum" ?
						null
					:
					this.props.match.params.type === "selfie" ?
						null
					: this.props.match.params.type === "chat" ?
						null
					:
						<div>
							<ChatView
								id = {this.props.match.params.id ? this.props.match.params.id : "lobby"}
								people={this.state.filteredPeople}
								parentUserMethod={this._filterPeople}
								parentSpeakerMethod={this.setLoudestSpeaker}
							/>

							<div
								id="roster"
								className={`white exclusion absolute center border border-white full-height-max overflow-auto ${this.state.showRoster ? "scaleInCenter" : "scaleOutCenter"}`}
							>
								<ul className="py3">
									{ Object.keys(this.state.roster).map((key, i) =>
										<li className="pb2 scaleIn" key={i}>
											<button
												className="px2 lowercase inline-block hover-bg"
												onClick={() => {
													this._goToChatFromUUID(key);
												}}
											>
												<span
													className="h5"
												>
													{this.state.roster[key].displayName}
												</span>

												<br/>

												<p
													className=""
												>
													{Utils.getLastSeen(this.state.roster[key].lastSeen)}
												</p>
											</button>

										</li>
									)}
								</ul>
							</div>
						</div>
				}


			</div>
		);
	}

	_resetState = () => {
		// if this is a page else go to scrum
		if (this.props.match.params.type) {
			this.props.history.push(`/`);
			// console.log("who here", this.state.filteredPeople)
			// this.refs.scene.goToThirdPerson(true);
			this._updateRoom();
		} else {
			// this assumes you're root '/'
			this._goToScrum();
		}

	}

	_goToScrum = () => {
		this.props.history.push(`/scrum`);
	}

	_goToChatFromUUID = (UUID) => {
		API.getChatID( UUID, (chatID) => {
			this._goToChat(chatID)
		});
	}

	_goToChat = (chatRoom) => {
		this.props.history.push(`/chat/${chatRoom}`);
	}

	_toggleRoster = () => {
		const showRoster = !this.state.showRoster
		this.setState({showRoster})
	}

	_updateStatus = (message, animation="fadePulse") => {
		this.setState({
			status: {
				message: message,
				animation: animation
			}
		});
	}

	setLoudestSpeaker = (ID) => {
		console.log('set loudest speaker')
		// this.refs.scene.goToSpeaker(ID);
		// else gotodollhouse
	}

	_filterPeople = (newPeople) => {
		// console.log('filtering people', newPeople)
		// let filteredPeople = {};
		// filteredPeople = !newPeople ? this.state.people : newPeople;
		if (newPeople) {
			this.setState({filteredPeople: newPeople});
		}
	}

	_toggleDebug() {
		const toggleState = this.state.showDebug ? false : true;
		this.setState({showDebug: toggleState});
	}

	_goToPage = (url) => {
		this.props.history.push(`/${url}`)
	}

	_updateRoom = () => {
		this._updateTitle();
		this._updateCameraControls();
	}

	_updateCameraControls = () => {
		this.refs.scene.toggleMovement(true);

		if (this.props.match.params.type === "selfie" || this.props.match.params.type === "avatar") {
			// do closeup portrait
			this.refs.scene.goToPortrait();
		} else {
			// do 3rd person view
			if (this.state.filteredPeople > 1) {
				this.refs.scene.goToThirdPerson();
			} else {
				this.refs.scene.goToSecondPerson(true);
			}
		}
	}

	_updateTitle = () => {
		// console.log('page type', this.props.match.params.type);
		const title = document.getElementById("pageTitle");
		// title.classList.remove("fadeOut");
		// title.classList.add("fadeOut");


		if (this.props.match.params.type === "avatar") {
			this.setState({
				title: {
					name: "ed·it av·a·tar",
					animation: "fadeOut delay"
				}
			});
		} else if (this.props.match.params.type === "chat") {
			this.setState({
				title: {
					name: "chit·ter-chat·ter",
					animation: "fadeOut delay"
				}
			});
		} else {
			this.setState({
				title: {
					name: "tel·e·port",
					animation: "fadeOut delay"
				}
			});
		}
	}
}

export default withRouter(Home);

import { Database, RealtimeDatabase, Timestamp } from './Firebase';

// const avatarList = {
//   cole: "d3aafa74-9dda-434b-a4c6-3f80d4b11e2f",
//   peter: "bbbd361d-b5ad-4c41-a64c-1fcf431b57f2",
//   peter2: "773f0d30-b3b1-46bd-8cd5-120879da9fad",
//   peterStatic: "f842cce0-a379-49b8-ad67-eb67388b6e36",
//   anand: "b089b4b8-f655-4b7f-bd0a-0cbe4b654830",
//   anand2: "e849e220-f04c-4c36-aa7b-0f76c6ac23a8",
//   amy: "dee6419c-b069-4c48-9e23-03aac19c308c",
//   chewy: "45e49fed-a990-4db1-87e0-c6eab9aa8e8a",
//   rob: "49e5bc53-fccf-4200-8b6d-125a5fbf366c",
//   elaine: "4f31d613-7bbf-4a98-b18d-764d04b37819",
//   woman: "8f4dbc70-599f-4179-a0db-b663c88e8e3e"
// }

class API {
	static getMutedState() {
		// todo: hack to fix no local mutedState sign in
		if ( localStorage.getItem("mutedState") ) {
			// console.log("local mute state", JSON.parse(localStorage.getItem("mutedState")))
			return JSON.parse(localStorage.getItem("mutedState"));
		} else {
			localStorage.setItem("mutedState", JSON.stringify(false));
			// console.log("no mute state", JSON.parse(localStorage.getItem("mutedState")))
			return JSON.parse(localStorage.getItem("mutedState"))
		}
	}

	static setMutedState(bool) {
		localStorage.setItem("mutedState", JSON.stringify(bool));
	}

	static getCurrentUUID() {
		// todo: hack to fix no local storage sign in
		if ( localStorage.getItem("currentUser") ) {
			return JSON.parse(localStorage.getItem("currentUser")).uid;
		} else {
			console.log("redirect");
			window.location.replace("/login");
		}
	}

	static getCurrentUser(cb) {
		let currentUID = this.getCurrentUUID();
		if (currentUID) {
			this.getUser(currentUID, (data) => {
				// console.log("get current", data)
				cb(data);
			});
		}
	}

	static getUser(UUID, cb) {
		Database.collection("Users").doc(UUID).get().then((doc) => {
			if (doc.exists) {
				// console.log('get user', doc.data());
				cb(doc.data());
			} else {
				// doc.data() will be undefined in this case
				console.log("No such user!");
			}
		}).catch(function(error) {
			console.log("Error getting document:", error);
		});
	}

	static getUsers(randomPeopleAmount=null, cb) {
		Database.collection("Users").orderBy('lastSeen', 'desc').get().then((querySnapshot) => {
			let people = {};
			// let currentUID = this.getCurrentUUID();

			if (randomPeopleAmount) {
				people = this._generateRandomUsers(randomPeopleAmount);
			}

			querySnapshot.forEach((doc) => {
				// console.log("query roster", doc.id, doc.data());
				if (doc.data()) {
					// console.log("doc data", doc.data(), doc.data().uid, currentUID)
					// filter me out so i dont' show up
					// if (doc.data().uid !== currentUID) {
					people[doc.id] = doc.data();
					// }
				}
			});

			cb(people);
		});
	}

	static _generateRandomUsers(numberOfRandos) {
		let randoms = {};
		let lastSeen = new Timestamp.now();

		[ ...Array(numberOfRandos).keys() ].map( (key, i) => {
			i = i+1;
			// console.log("randos", key, i)

			return randoms[key] = {
				displayName: `Random Person ${i}`,
				email: `random${i}@email.com`,
				lastSeen: lastSeen,
				uid: key
			}
		});

		return randoms;
	}

	// chat services
	static getChat(ID, cb) {
		const chatRef = Database.collection("Chats").doc(ID);

		chatRef.get().then((doc) => {
			if (doc.exists) {
				this.joinChat(doc.id);
				cb(doc.data());
			} else {
				console.log("No such chat!");
			}
		}).catch(function(error) {
			console.log("Error getting chat", error);
		});
	}

	static joinChat(chatID, peerID, cb) {
		const docRef = Database.collection("Chats").doc(chatID).collection("activeUsers").doc(this.getCurrentUUID());

		docRef.set({
			peerID: peerID,
			lastSeen: new Date()
		}).then( () => {
		    return cb()
		}).catch(function(error) {
		    console.error("Error writing document: ", error);
		});

	}

	static leaveChat(chatID) {
		this.removePeerFromChat(chatID, this.getCurrentUUID());
	}

	static removePeerFromChat(chatID, peerUID) {
		const docRef = Database.collection("Chats").doc(chatID).collection("activeUsers").doc(peerUID);

		docRef.delete().then(() => {
			console.log("Document successfully deleted!");
		}).catch(function(error) {
			console.error("Error removing document: ", error);
		});
	}

	static unsubscribeWatchChatUsers(ID, cb) {
		console.log("unsubscribing", ID);
		Database.collection("Chats").doc(ID).collection("activeUsers").onSnapshot(() => {
			cb();
		});
	}

	static watchChatUsers(chatID, cb) {
		// todo: no need to watch all users i don't think
		return Database.collection("Chats").doc(chatID).collection("activeUsers").onSnapshot((querySnapshot) => {
	        let users = {};

			querySnapshot.forEach((doc) => {
	            users[doc.id] = doc.data();
			});

			cb(users);
		});
	}

	// static chatRef.add({
	// 	users: [ID, this.getCurrentUUID()]
	// }).then((doc) => {
	// 	cb(doc.id);
	// });

	// find existing chat
	static getChatID(ID, cb) {
		// console.log('chats ' + ID)
		const chatRef = Database.collection("Chats");

		// query for all chats with My UUID
		Database.collection("Chats").where("users", "array-contains", this.getCurrentUUID()).get().then((querySnapshot) => {

			let chatID = null;
			// check for other UUID in all my uuid results
			querySnapshot.forEach((doc) => {
				if (doc.data().users.includes(ID)) {
					// console.log("found in each:", doc.exists, doc.data());
					chatID = doc.id;
				}
			});

			if (!chatID) {
				console.log("No such chat so creating one!");
				chatRef.add({
					"users": [ID, this.getCurrentUUID()],
					"activeUsers": [ID, this.getCurrentUUID()]
				}).then((doc) => {
					cb(doc.id);
				});
			} else {
				// return existing chat
				cb(chatID);
			}

		}).catch(function(error) {
			console.log("Error getting document:", error);
		});
	}

	// get chat users
	static getChatUsers(ID, cb) {
		Database.collection("Chats").doc(ID).get().then((doc) => {
			if (doc.exists) {
				cb(doc.data());
			} else {
				console.log("No such user!");
			}
		}).catch(function(error) {
			console.log("Error getting document:", error);
		});
	}

	// get chat userID
	static getOtherChatUserID(ID, cb) {
		this.getChatUsers(ID, (array) => {
			const filteredData = array.users.filter( userID => userID !== this.getCurrentUUID() );
			cb(filteredData[0]);
		})
	}

	static setCurrentUserPlayerUID(playerUID) {
		Database.collection("Users").doc(this.getCurrentUUID()).set({
			playerUID: playerUID
		}, {
			merge: true
		});
	}

	static setCurrentUserAvatarData(avatarCode, modelInfo, cb) {
		Database.collection("Users").doc(this.getCurrentUUID()).set({
			avatarCode, modelInfo
		}, {
			merge: true
		}).then(() => {
			cb();
		});
	}

	static setCurrentUserAvatarCode(avatarCode) {
		Database.collection("Users").doc(this.getCurrentUUID()).set({
			avatarCode: avatarCode
		}, {
			merge: true
		});
	}

	static setCurrentUserModelInfo(modelInfo) {
		Database.collection("Users").doc(this.getCurrentUUID()).set({
			modelInfo: modelInfo
		}, {
			merge: true
		});
	}

	static setCurrentAvatarMovement(data) {
		// console.log('setting data', data)
		// we usually at the parent level only fire this if more than 1
		RealtimeDatabase.ref('users/' + this.getCurrentUUID()).set(data);
	}

	static watchAvatarMovement(userID, cb) {
		return RealtimeDatabase.ref('users/' + userID).on('value', (snapshot) => {
			// console.log('getting snap', snapshot.val())
			cb(snapshot.val());
		});
	}
}

export default API;
// import { Database } from './Firebase';
import * as THREE from 'three';
import Utils from './Utils.js';
// import sceneBG from '../res/boardroom.glb'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

let bgColor = 0xe5e5e5;
let gridColor = 0x000000;

if (Utils.isNight()) {
	bgColor = 0x222222;
	gridColor = 0xffffff;
}

let grid = new THREE.GridHelper( 6000, 100, gridColor, gridColor );

class SceneEnvironment {
	static init(sceneObject) {

		// do stuff if mobile for env
		// if (Utils.isMobile()) {
		// } else {
			// vr scene
			// this._loadGLTF(sceneBG, (data) => {
			// 	sceneObject.add( data );
			// });;
		// }

		// setup grid environment
		sceneObject.background = new THREE.Color( bgColor );
		sceneObject.fog = new THREE.Fog( bgColor, 400, 2000 );

		// var grid = new THREE.GridHelper( 6000, 100, gridColor, gridColor );
		grid.material.opacity = .1;
		grid.material.transparent = true;
		grid.name = "grid";
		sceneObject.add( grid );


		// GROUND
		var shadowMaterial = new THREE.ShadowMaterial();
		shadowMaterial.opacity = 0.05;

		// var ground = new THREE.Mesh( groundGeo, groundMat );
		var ground = new THREE.Mesh( new THREE.PlaneBufferGeometry( 50000, 50000 ), shadowMaterial );

		ground.position.y = 0;
		ground.rotation.x = - Math.PI / 2;
		ground.receiveShadow = true;
		sceneObject.add( ground );

		// lights
		let light = new THREE.AmbientLight( 0x444444, 1 ); // soft white light scene.add( light );
		light.position.set( 0, 200, 0 );
		sceneObject.add( light );

		let hemiLight = new THREE.HemisphereLight( 0xFFEECC, 0x77DDFF, .2 );
		// hemiLight = new THREE.HemisphereLight( 0x77DDFF, 0xFFCC99, .2 );
		hemiLight.position.set( 0, 240, 0 );
		sceneObject.add( hemiLight );

		let hemiLightHelper = new THREE.HemisphereLightHelper( hemiLight, 20 );
		sceneObject.add( hemiLightHelper );

		// direction light
		let dirLight = new THREE.DirectionalLight( 0xFFFFFF, 1 );
		dirLight.position.set( -1, 2, 1 );
		dirLight.position.multiplyScalar( 190 );

		dirLight.castShadow = true;

		dirLight.shadow.mapSize.width = 2056;
		dirLight.shadow.mapSize.height = 2056;

		dirLight.shadow.radius = 2;

		const d = 300;

		dirLight.shadow.camera.left = -d;
		dirLight.shadow.camera.right = d;
		dirLight.shadow.camera.top = d;
		dirLight.shadow.camera.bottom = -d;

		dirLight.shadow.camera.far = 10000;
		dirLight.shadow.bias = -0.0001;
		sceneObject.add( dirLight );

		// let dirLightHeper = new THREE.DirectionalLightHelper( dirLight, 20 );
		// sceneObject.add( dirLightHeper );

		//ADD CUBE
		// const boxGeometry = new THREE.BoxGeometry(20, 20, 20);
		// const boxMaterial = new THREE.MeshBasicMaterial({ color: '#433F81' });
		// let cube = new THREE.Mesh(boxGeometry, boxMaterial);
		// cube.position.set( 0, 160, 0 );
		// sceneObject.add( cube );
	}

	static _updateBG(sceneObject) {
		if (sceneObject.background) {
			sceneObject.background = null;
			sceneObject.fog = null;

			sceneObject.remove( grid );

		} else {
			sceneObject.background = new THREE.Color( bgColor );
			sceneObject.fog = new THREE.Fog( bgColor, 400, 2000 );

			sceneObject.add( grid );

		}
	}

	static _loadGLTF(url, cb) {
			// load preset body
			let loader = new GLTFLoader();

			// Load a glTF resource
			loader.load(url, (gltf) => {

				gltf.scene.traverse( ( child ) => {
					if ( child.isMesh ) {
						// console.log("child name", child.name)

						child.castShadow = true;
						child.receiveShadow = true;

						child.material.metalness = 0;

						// if (child.name === "Hover_Table") {
						// 	child.material = new THREE.MeshNormalMaterial();
						// }
					}
				})

				cb(gltf.scene);
			},
				// called while loading is progressing
				(xhr) => {
					// console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
					if (xhr.loaded === xhr.total) {
						console.log('done loading gltf')
					}
				},
				// called when loading has errors
				(error) => {
					console.log( 'An error happened', error );
				}
			);
		}
}

export default SceneEnvironment;
import * as React from 'react';

// Router stuff
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Utils from "./components/Utils.js";

// Routes
import Home from './Home.js';
import Login from './Login.js';
import Redirect from './Redirect.js';

class TeleportRouter extends React.Component {

	// set bg color
	componentDidMount() {
		document.documentElement.classList.add(`bg-${Utils.isDayOrNight()}`);
		document.body.classList.add(`bg-${Utils.isDayOrNight()}`);
	}

	render() {
		return (
			<Router>
				<div>
					<Switch>
						<Route exact path="/" component={Home} />
						<Route exact path="/login" component={Login} />
						<Route exact path="/:type" component={Home} />
						<Route exact path="/:type/:id" component={Home} />

						<Route component={Redirect} />
					</Switch>
				</div>
			</Router>
		);
	}
}

export default TeleportRouter;
// https://api.avatarsdk.com/samples/threejs/
// https://api.avatarsdk.com/#id25

// import { Database } from './Firebase';
import axios from 'axios';
import * as THREE from 'three';
import API from './API.js';

import AvatarSDKLoaders from './AvatarSDKLoaders'

const API_ROOT = 'https://api.avatarsdk.com';

const client_id = "H201EPCR8CuuBdHHxTSSI6Vuiv4klaktVoXs6EcO";
const client_secret = "xpOImLlaDYuIRsd1eCDMAc0IRNfG0NFCGa4lMKgh9YZMdZWKZ3Bu2xIF5TQvPlGxz7OTJHqMDgEf8pM76xbjBVgPchcS8yRu0EhsiRxiiZ3Z7NP3ta11HfTOVUXOzH3T";

// const client_id = "H201EPCR8CuuBdHHxTSSI6Vuiv4klaktVoXs6EcO";
// const client_secret = "xpOImLlaDYuIRsd1eCDMAc0IRNfG0NFCGa4lMKgh9YZMdZWKZ3Bu2xIF5TQvPlGxz7OTJHqMDgEf8pM76xbjBVgPchcS8yRu0EhsiRxiiZ3Z7NP3ta11HfTOVUXOzH3T";
// const authData = `grant_type=client_credentials&client_id=${client_id}&client_secret=${client_secret}`;
// const playerUID = "ab8ee6ec-fa96-469a-9624-f0d143e8c876"

let authToken = null;
let authType = null;

let mesh = null;
let texture = null;

let _progress = {};

class AvatarSDK {

	static authorize() {
		return new Promise((resolve, reject) => {
			if (authToken) {
				resolve();
			} else {
				var authForm = new FormData();
				authForm.append('grant_type', 'client_credentials');

				let auth = 'Basic ' + btoa(client_id + ':' + client_secret);

				axios({
					method: "POST",
					headers: {
						Authorization: auth
					},
					url: "https://api.avatarsdk.com/o/token/",
					data: authForm
				}).then( (res) => {
					authToken = res.data.access_token;
					authType = res.data.token_type;
					console.log("avatar sdk initiated");
					resolve();
				}, (error) => {
					console.log(error);
					reject(error);
				});
			}
		});
	}

	static _getAvatarSDKPlayerUID(user, cb) {
		// check for player id if not create one
		console.log("user player", user.playerUID)
		if (user.playerUID) {
			console.log("existing player", user.playerUID)
			return cb(user.playerUID);
		} else {
			const fd = new FormData();
			fd.append("comment", user.uid);

			axios
				.request({
					method: "POST",
					url: "https://api.avatarsdk.com/players/",
					headers: {
						'Authorization': `${authType} ${authToken}`,
						'Content-Type': "multipart/form-data"
					},
					data: fd
				})
				.then(response => {
					user.playerUID = response.data.code;
					console.log("new player", user.playerUID)
					API.setCurrentUserPlayerUID(user.playerUID);

					return cb(user.playerUID);
				})
				.catch(error => {
					alert("Failed to create new AvatarSDK user: ", error);
					return cb(null);
				});
		}

	}

	// static getAvatarThumbnail(avatarCode, cb) {
	// 	let authPromise = this.authorize();

	// 	authPromise.then(() => {
	// 		axios
	// 			.post("https://api.avatarsdk.com/o/token/", authData)
	// 			.then(response => {
	// 				axios
	// 					.request({
	// 						method: "GET",
	// 						url: "https://api.avatarsdk.com/avatars/" + avatarCode + "/thumbnail/",
	// 						responseType: "json",
	// 						headers: {
	// 							'Authorization': response.data.token_type + " " + response.data.access_token,
	// 							"X-PlayerUID": playerUID,
	// 							"Content-Type": "image/jpeg"
	// 						}
	// 					})
	// 					.then(response => {
	// 						// var arrayBuffer = new Uint8Array(response.data);
	// 						// var contentType = response.headers.contentType;
	// 						// var blob = new Blob([arrayBuffer], { type: contentType });
	// 						// var imageURL = URL.createObjectURL(blob);
	// 						console.log("response", response)
	// 						cb(response);
	// 					})
	// 					.catch(error => console.error(error));
	// 			})
	// 			.catch(error => console.error(error));
	// 	});
	// }

	static uploadAvatar(user, photoURL,cb) {
		let authPromise = this.authorize();

		authPromise.then(() => {

			this._getAvatarSDKPlayerUID( user, (playerUID) => {

				const fd = new FormData();
				fd.append("name", user.displayName);
				fd.append("pipeline", "head_2.0");
				fd.append("pipeline_subtype", "head/mobile");
					fd.append(
					"resources",
					JSON.stringify({
						model_info: {
							plus: [
								'age',
								'gender',
								'hair_color',
								'race',
								'skin_color'
							]
						},
						// blendshapes: {
						// 	'plus': [
						// 		'mobile_51'
						// 	],
						// },
						avatar_modifications: {
							plus: {
								repack_texture: true,
								// allow_modify_neck: true,
								remove_smile: true,
								// slightly_cartoonish_texture: true,
								// generated_haircut_faces_count: 512,
								generated_haircut_texture_size: 1024
								// hair_color: {
								// 	"red": 0, "green": 255, "blue": 0
								// },
								// eye_iris_color: {
								// 	"red": 0, "green": 255, "blue": 0
								// }
							}
						}
					})
				);

				fd.append("photo", photoURL);
				// console.log('fd', fd)

				axios
					.request({
						method: "POST",
								url: "https://api.avatarsdk.com/avatars/",
						headers: {
							'Authorization': `${authType} ${authToken}`,
							'Content-Type': "multipart/form-data",
							'X-PlayerUID': playerUID
						},
						data: fd
						})
					.then( res => {
						this._handleGeneratingProcess(res.data.url, playerUID, cb);
					})
					.catch(error => console.error(error));
			})


		});
	}


	static _handleGeneratingProcess(url, playerUID, cb) {
		let generatingProcessInterval = setInterval(() => {
			axios
				.request({
					method: "GET",
					url: url,
					headers: {
						'Authorization': `${authType} ${authToken}`,
						'X-PlayerUID': playerUID,
						"Content-Type": "multipart/form-data"
					}
				})
				.then(res => {
					// progress from the response seems to be broken, only return 0% until the certain point and then straight to 95% and 100%
					// this.progressRef.current.innerHTML = res.data.progress + "%";
					console.log(res.data.status, res.data.progress + "%", res)

					if (res.data.status === "Completed") {
						clearInterval(generatingProcessInterval);

						let userData = {};
						userData.avatarCode = res.data.code;
						// console.log("final data", res.data.code)

						this.getAvatarModelInfo(userData.avatarCode, playerUID, (modelInfo) => {
							console.log("got model info", modelInfo);
							userData.modelInfo = modelInfo;

							API.setCurrentUserAvatarData(userData.avatarCode, userData.modelInfo, () => {
								cb(userData);
							});
						});
					} else if (res.data.status === "Failed") {
						console.log("failed", res.data.status, res);
						clearInterval(generatingProcessInterval);
						cb(null);
					}
				})
				.catch(error => {
					console.log("error", error);
					clearInterval(generatingProcessInterval);
				});
		}, 3000);
	}

	static getAvatarModelInfo(avatarCode, playerUID, cb) {
		let authPromise = this.authorize();

		authPromise.then(() => {
			axios
				.request({
					method: "GET",
					url: "https://api.avatarsdk.com/avatars/" + avatarCode + "/model_info/",
					headers: {
						'Authorization': `${authType} ${authToken}`,
						'X-PlayerUID': playerUID,
						"Content-Type": "multipart/form-data"
					}
				})
				.then(res => {
					// console.log("model info", res.data, res)
					cb(res.data);
				})
				.catch(error => {
					console.log(error);
				});
		});
	}

	static getAvatar(avatarCode, playerUID, cb) {
		let authPromise = this.authorize();

		authPromise.then(() => {
			// console.log("auth bear", authToken);

			if (!authToken) {
				alert('Empty authorization bearer');
				return;
			}

			if (!avatarCode) {
				alert('Empty avatar code');
				return;
			}

			// cleanup();

			var textureUrl = [API_ROOT, 'avatars', avatarCode, 'texture/'].join('/');
			var meshUrl = [API_ROOT, 'avatars', avatarCode, 'mesh/'].join('/');
			var blendshapesUrl = [API_ROOT, 'avatars', avatarCode, 'blendshapes/?fmt=ply'].join('/');

			var hairTextureUrl = [API_ROOT, 'avatars', avatarCode, 'haircuts/generated/texture/'].join('/');
			var hairMeshUrl = [API_ROOT, 'avatars', avatarCode, 'haircuts/generated/mesh/'].join('/');

			// resetProgress(textureUrl, meshUrl);

			var fileLoader = new AvatarSDKLoaders.FileLoader(authToken, playerUID);

			// --- retrieve texture ----------------------------------------------------
			const texturePromise = new Promise((resolve, reject) => {
				new AvatarSDKLoaders.TextureLoader(fileLoader).load(textureUrl, function onTextureLoaded(img) {
					texture = img;
					resolve(texture);
				}, this.onProgress, this.onError);
			});

			// --- retrieve mesh -------------------------------------------------------
			const meshPromise = new Promise((resolve, reject) => {
				new AvatarSDKLoaders.GeometryLoader(fileLoader).load(meshUrl, 'model.ply', function onGeometryLoaded(geometry) {
					mesh = geometry;
					resolve(mesh);
				}, this.onProgress, this.onError);
			});

			// --- retrieve hair texture ----------------------------------------------------
			const hairTexturePromise = new Promise((resolve, reject) => {
				new AvatarSDKLoaders.TextureLoader(fileLoader).load(hairTextureUrl, function onTextureLoaded(img) {
					texture = img;
					resolve(texture);
				}, this.onProgress, this.onError);
			});

			// --- retrieve hair mesh -------------------------------------------------------
			const hairMeshPromise = new Promise((resolve, reject) => {
				new AvatarSDKLoaders.GeometryLoader(fileLoader).load(hairMeshUrl, 'hair.ply', function onGeometryLoaded(geometry) {
					mesh = geometry;
					resolve(mesh);
				}, this.onProgress, this.onError);
			});

			// --- retrieve blendshapes -------------------------------------------------------
			// https://developer.apple.com/documentation/arkit/arfaceanchor/blendshapelocation
			const blendshapePromise = new Promise((resolve, reject) => {
				new AvatarSDKLoaders.GeometryLoader(fileLoader).load(
					blendshapesUrl,
					this.blendshapeArrayWithExtension('.ply'),
					function onGeometryLoaded(geometry) {
						// console.log("blendshape geo", geometry);

						geometry = geometry || [];
						// this removes all named attributes for some reason
						let blendshapeGeometry = geometry.map((g) => g.toNonIndexed());

						// console.log("geo", geometry, blendshapeGeometry)
						resolve(blendshapeGeometry);
					}, this.onProgress, this.onError);
			});

			Promise.all([texturePromise, meshPromise, hairTexturePromise, hairMeshPromise, blendshapePromise]).then(resolvedData => {
				let texture = resolvedData[0];
				let baseGeometry = resolvedData[1];
				let hairTexture = resolvedData[2];
				let hairGeometry = resolvedData[3];
				let blendshapeGeometry = resolvedData[4];

				// console.log("resolved promiseAll", resolvedData);

				let materialArgs = {
					color: 0xffffff,
					side: THREE.DoubleSide,
					morphTargets: true,
					// skinning: true,
					map: texture
				};

				let hairMaterialArgs = {
					color: 0xffffff,
					// side: THREE.DoubleSide,
					// morphTargets: true,
					transparent: true,
					map: hairTexture
				};

				let material = new THREE.MeshBasicMaterial(materialArgs);
				let hairMaterial = new THREE.MeshBasicMaterial(hairMaterialArgs);

				// baseGeometry.computeBoundingBox();
				// let modelHeight = baseGeometry.boundingBox.getSize(new THREE.Vector3()).y;

				// if (modelHeight < 30) {
					// let factor = 30 / modelHeight;
					// baseGeometry.scale(factor, factor, factor);
					// if (Array.isArray(blendshapeGeometry)) {
					// 	blendshapeGeometry.forEach((g) => g.scale(factor, factor, factor));
					// } else {
					// 	blendshapeGeometry.scale(factor, factor, factor);
					// }
				// }

				// scale up geometries
				let scaleFactor = 1.14;

				baseGeometry.scale(scaleFactor, scaleFactor, scaleFactor);
				hairGeometry.scale(scaleFactor, scaleFactor, scaleFactor);

				if (Array.isArray(blendshapeGeometry)) {
					blendshapeGeometry.forEach((g) => g.scale(scaleFactor, scaleFactor, scaleFactor));
				} else {
					blendshapeGeometry.scale(scaleFactor, scaleFactor, scaleFactor);
				}

				// blendshape mapping
				if (Array.isArray(blendshapeGeometry)) {
					baseGeometry.morphAttributes.position = blendshapeGeometry.map((g) => g.attributes.position);

					// blendshapeGeometry.map((g, index) => {
					// 	// console.log("map", index, g, g.attributes.position)
					// 	baseGeometry.morphAttributes.position[index] = g.attributes.position;

					// 	// baseGeometry.morphTargets.push( { name: "target" + index, vertices: g.attributes.position } );
					// 	// baseGeometry.morphTargets.push( g.attributes.position );
					// });
				} else {
					baseGeometry.morphAttributes.position = [];
					baseGeometry.morphAttributes.position[0] = blendshapeGeometry.attributes.position;
				}

				// geometry = new THREE.BufferGeometry().fromGeometry( geometry );
				let baseMesh = new THREE.Mesh(baseGeometry, material);
				let hairMesh = new THREE.Mesh(hairGeometry, hairMaterial);
				// baseMesh.updateMorphTargets();

				baseMesh.traverse( child => {
					if (child.isMesh) {
						// console.log('mesh', child.name)
						child.castShadow = true;
						child.receiveShadow = true;
					}
				});

				hairMesh.traverse( child => {
					if (child.isMesh) {
						// console.log('mesh', child)
						child.castShadow = true;
						child.receiveShadow = true;
					}
				});

				baseMesh.add(hairMesh);
				baseMesh.position.set(0, .135, .0525);

				// console.log('return basemesh');
				cb(baseMesh);
			});

		})
	}



	// === service functions =====================================================
	static onError(data, event) {
		var element = document.createElement('div');
		element.classList.add('error');

		console.log("service error", data, event)

		var dataStr = '';
		if (event.target.responseType === 'arraybuffer') {
			let decoder = new TextDecoder('utf-8');
			dataStr = decoder.decode(data);
		} else if (event.target.responseType === 'json') {
			dataStr = JSON.stringify(data);
		} else {
			dataStr = String(data);
		}
		let message = [event.target.status, event.target.statusText, ':', dataStr].join(' ');

		element.innerHTML = message;

	}


	static onProgress(event) {
		_progress[event.target.responseURL] = event.loaded / event.total;

		// let values = Object.values(_progress);
		// let progress = values.reduce(function (a,i){return a+i;}, 0) / values.length;

		// progress_container.innerHTML = Math.floor(progress * 100);
	}


	static cleanup() {
		if (!!mesh) {
			this.scene.remove(mesh);
			mesh = null;
		}

		if (!!texture) {
			texture = null;
		}
	}

	static resetProgress(textureUrl, meshUrl) {
		// progress_container.innerHTML = '0';
		_progress[textureUrl] = 0;
		_progress[meshUrl] = 0;
	}

	static blendshapeArray = [
		"jawOpen",

		"eyeBlinkLeft",
		"eyeBlinkRight",

		"mouthSmileLeft",
		"mouthSmileRight",
		"mouthFrownLeft",
		"mouthFrownRight",
		"mouthFunnel",
		"mouthPucker",
		"mouthLeft",
		"mouthRight",

		"browInnerUp",

		"eyeLookUpLeft",
		"eyeLookOutLeft",
		"eyeLookDownLeft",
		"eyeLookInLeft",

		"eyeLookUpRight",
		"eyeLookOutRight",
		"eyeLookDownRight",
		"eyeLookInRight"
	];

	static blendshapeArrayWithExtension(string) {
		let newBlendshapeArray = [];

		this.blendshapeArray.forEach( (key, i) => {
			const newKey = `${key}${string}`
			newBlendshapeArray.push(newKey);
		})

		return newBlendshapeArray;
	};

	static blendshapes() {
		let blendshapes = {};
		this.blendshapeArray.forEach( (key, i) => {
			blendshapes[key] = i;
		})
		// console.log(blendshapes)
		return blendshapes
	};
}

export default AvatarSDK;
import React from 'react';
import { withRouter } from "react-router-dom";

// components
import API from './API.js';

import AvatarSDK from './AvatarSDK.js';

class AvatarCreation extends React.Component {

	constructor(props)
	{
		super(props);

		this.state ={
			file: null,
			avatarGenerating: false,
			status: {
				message: null,
				animation: null
			}
		};

		this.onChange = this.onChange.bind(this);
	}

	componentDidMount() {
		API.getCurrentUser(currentUserData => {
			if (!currentUserData.avatarCode) {
				console.log("no avatar code", currentUserData.avatarCode, currentUserData);
				this._generateAvatar();
			}

			let newPeople = {};
			newPeople[currentUserData.uid] = currentUserData;

			// console.log("new people", currentUserData);

			this.props.parentUserMethod(newPeople);
		});
	}

	componentDidUpdate(prevProps, prevStates) {
	}

	componentWillUnmount() {
		// unmount geoposition watch
		console.log("unmount avatar creation");
	}

	render() {

		return (
			<div
				className={`flex items-center justify-center`}
				style={{
					height: "100vh"
				}}
			>
				<div className={`flex flex-column mb4 exclusion absolute bottom-0 ${this.state.avatarGenerating ? "scaleOut" : "scaleIn"}`}>
					<input
						className="z2 mb2 center hidden"
						ref={fileInput => this.fileInput = fileInput}
						type="file"
						accept="image/*"
						onChange={this.onChange}
					/>

					<button
						className="scaleIn pill-button mb3"
						onClick={this._triggerInputFile}
					>
						select a photo
					</button>

					<button
						className="scaleIn pill-button mb3"
						onClick={this._generateAvatar}
					>
						use profile photo
					</button>

					<button
						className="scaleIn pill-button-inverse bg-white black"
						onClick={ () => {
							this.props.history.push('selfie')
						}}
					>
						Donzo
					</button>
				</div>
				<h4 className={`absolute mb4 pb4 ${this.state.avatarGenerating ? "scaleIn fadePulse" : "scaleOut"}`}>
					Generating your face...
				</h4>
			</div>
		);
	}

	_triggerInputFile = () => {
		this.fileInput.click();
	}

	onChange(e) {
		console.log("file change", e, e.target.files)

		this.setState({
			file:e.target.files[0]
		}, () => {
			this._generateAvatar();
		});
	}

	_generateAvatar = () => {
		this.props.parentStatusMethod("generating that beautiful face");
		this.setState({avatarGenerating: true});

		API.getCurrentUser((userData) => {
			let newUsers = {}
			let user = userData;

			let photoFileBlob = this.state.file;

			const photoFilePromise = new Promise((resolve, reject) => {
				if (!photoFileBlob && user.photoURL) {
					fetch(user.photoURL)
						.then( (response) => {
							// console.log("first photo response", response.blob())
							return response.blob();
						}).then( (blob) => {
							// here the image is a blob
							photoFileBlob = blob;
							// console.log("final photo response", photoFileBlob)
							resolve();
					});
				} else {
					resolve();
				}

			});

			photoFilePromise.then(() => {
				AvatarSDK.uploadAvatar(user, photoFileBlob, (data) => {
					if (data) {
						console.log("final png data", data.code, data );
						// this.props.parentStatusMethod("finishing touches");

						user.avatarCode = data.avatarCode;
						user.modelInfo = data.modelInfo;
						newUsers[user.uid] = user;

						this.props.parentUserMethod(newUsers);

						this.setState({
							file: null,
							avatarGenerating: false
						}, () => {
							// this needs to set after data is written
							window.location.reload(false);
						});
					} else {
						// avatar generation failed
						this.props.parentStatusMethod("Face fail, try another photo");

						this.setState({
							file: null,
							avatarGenerating: false
						});
					}

				});
			}).catch(error => console.error(error));
		});

	}

	_resetState = () => {
		this.props.history.push('/');
	}

}

export default withRouter(AvatarCreation);

import React from "react";
// import "../css/DetailPanel.css";
import Peer from 'peerjs';
// apis
import API from "./API.js";
import VideoParticipant from "./VideoParticipant.js";

import '../css/ChatView.css';

class ChatView extends React.Component {

	constructor() {
		super();

		this.state = {
			myPeerID: null,
			calledStreams: {},
			videoStreams: {},
			broadcastStream: null,
			speaker: null,
			speakerVolumes: {},
			muted: API.getMutedState()
		}

		this.peer = null;
		this.setSpeakerVolume = this.setSpeakerVolume.bind(this);
		// this.findLoudestSpeaker = this.findLoudestSpeaker.bind(this);
	}

	componentDidMount() {
		console.log("chat mount", this.props.id);
        this.beforeUnloadListener();

		// peer
		this.peer = new Peer(
			{
				host: "peter-peerjs.spatial.io",
				path: "/myapp",
				debug: 1,
				secure: true
			}
		);
		this.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

		this._initStream( (stream) => {
			// answer other calls
			console.log("init stream")

			this.peer.on('call', (call) => {
				call.answer(stream); // Answer the call with an A/V stream.
				call.on('stream', (remoteStream) => {
					// Show stream in some <video> element.
					console.log("receiving someone else", call.peer, call.open);
					this._createReceivingStream(call, remoteStream)
				});
				call.on('close', () => {
					console.log("connection closed", call.peer, this.props.people[call.peer]);

					// just destroy everything
					// this.peer.destroy();
					let videoStreams = this.state.videoStreams;

					if (videoStreams[call.peer]) {
						videoStreams[call.peer].call.close();
						delete videoStreams[call.peer];

						this.setState({videoStreams});
					}

					this._callPeer(call.peer);

					// if (this.props.people[call.peer]) {
					// 	// try calling this person again cause he should be in
					// 	// this.peer.reconnect();
					// 	this._callPeer(call.peer);
					// } else {
					// 	let videoStreams = this.state.videoStreams;

					// 	if (videoStreams[call.peer]) {
					// 		videoStreams[call.peer].container.remove();
					// 		videoStreams[call.peer].call.close();
					// 		delete videoStreams[call.peer];

					// 		this.setState({videoStreams});
					// 	}
					// }
				});
				call.on('error', (error) => {
					console.log("connection error", call.peer, error);
				});
			});
		});

		// when my connection opens call everybody
		this.peer.on('open', (myPeerID) => {
			console.log('my peer id is', myPeerID, this.peer.connections);
			this.setState({ myPeerID });

			API.joinChat(this.props.id, myPeerID, () => {

				// once you've joined setup user watch on chat
				this.unsubscribeChatWatch = API.watchChatUsers(this.props.id, (users) => {
					console.log("chat users changed", users);
					let newChatUsers = {};

					// if it's an existing one with same peer maybe don't do it
					let userDataPromises = Object.keys(users).map((key, i) => {
						if (key !== API.getCurrentUUID()) {
							this._callPeer(users[key].peerID);
						}

						return new Promise((resolve, reject) => {
							// if (key === API.getCurrentUUID()) resolve();
							// console.log("get user key", key, users, this.props.people[key], API.getCurrentUUID());
							if (this.props.people[key]) {
								newChatUsers[key] = this.props.people[key];
								resolve();
							} else {
								API.getUser(key, (userData) => {
									// console.log("user change", key, userData);
									newChatUsers[key] = userData;
									newChatUsers[key].peerID = users[key].peerID;
									// resolve promise
									resolve();
								});
							}
						});
					});

					Promise.all(userDataPromises).then( (results) => {
						console.log("promise end", newChatUsers);
						// this._cleanupCalls(newChatUsers);
						this.props.parentUserMethod(newChatUsers);
					});
				});
			});

		});

		this.peer.on('error', (error) => {
			const peerErrorID = error.message.split(" ").pop();
			let errorUID = Object.keys(this.props.people).find(key => this.props.people[key].peerID === peerErrorID);
			console.log('peer connection error', error.message, peerErrorID, errorUID);

			// todo: this is super suspect since you're removing someone else remove that person from chat
			if (errorUID) {
				API.removePeerFromChat(this.props.id, errorUID)
			}
		});
	}

	componentDidUpdate(prevProps, prevState) {

		if (this.props.people !== prevProps.people) {
			// this._callPeers();
			console.log("people changed");
		}

		if (this.state.videoStreams !== prevState.videoStreams) {
			console.log("videoStreams update", this.state.calledStreams, this.state.videoStreams);
		}

		if (this.state.calledStreams !== prevState.calledStreams) {
			console.log("calledStreams update", this.state.calledStreams, this.state.videoStreams);
		}

		if (this.state.muted !== prevState.muted) {
			// API.setMutedState(this.state.muted);
		}

		// if (this.state.speakerVolumes !== prevState.speakerVolumes) {
		// 	console.log("updated speakers");
		// }
	}

	componentWillUnmount() {
		this._closeChatRoom();
	}

	render() {
		return (
			<div>
				<div
					id="participantBar"
					ref={participantBar => {this.participantBar = participantBar}}
					className="z3 absolute centerX flex mt2"
				>
					{ Object.keys(this.props.people).map((key, i) =>
						key !== API.getCurrentUUID() ?
							<div className="scaleIn mx1" key={i}>
								<VideoParticipant
									id={this.props.people[key].uid}
									email={this.props.people[key] ? this.props.people[key].email : null}
									displayName={this.props.people[key] ? this.props.people[key].displayName : null}
									stream={this.state.videoStreams[this.props.people[key].peerID] ? this.state.videoStreams[this.props.people[key].peerID].stream : null}
									parentSpeakingMethod={this.setSpeakerVolume}
								/>
							</div>
						: null
					)}
				</div>
				<div
					className="absolute bottom-0 centerX exclusion mb-responsive"
				>
					<button
						className={`pill-button border border-red scaleIn`}
						onClick={() => {
							this._toggleMute();
						}}
					>
						<i className={`material-icons pr1`}>{this.state.muted ? "mic_off" : "mic_none"}</i>
						{this.state.muted ? "muted" : "mute"}
					</button>
				</div>
			</div>
		);
	}

	_check() {
		console.log("broadcast stream", this.state.broadcastStream, this.props.people, this.state.videoStreams)
	}

	_mute(muteState) {
		if (this.state.broadcastStream) {
			// if the currentState is muted, then unmute everything
			console.log("broadcastStreams", this.state.broadcastStream.stream.getAudioTracks())
			this.state.broadcastStream.stream.getAudioTracks()[0].enabled = !muteState;
		}

		API.setMutedState(muteState);
		this.setState({muted: muteState});
	}

	_toggleMute() {
		this._mute(!this.state.muted);
	}

	_cleanupCalls(userIDs) {
		console.log('cleanup calls', this.state.videoStreams, userIDs)
		let videoStreams = this.state.videoStreams;

		Object.keys(this.state.videoStreams).map( (key, i) => {
			if (!userIDs[key]) {
				if (this.state.videoStreams[key]) {
					this.state.videoStreams[key].container.remove();
					this.state.videoStreams[key].call.close();
					delete videoStreams[key];
				}
			}
			return
		});

		this.setState({videoStreams});
	}

	_callPeers() {
		// establish peer connection with their id
		this._initStream( (stream) => {
			Object.keys(this.props.people).map( (key, i) => {
				if (this.peer && key !== API.getCurrentUUID()) {
					// initiate call
					const call = this.peer.call(this.props.people[key].peerID, stream);
					// console.log('calling single peer', call, this.peer.connections)
					call.on('stream', (remoteStream) => {
						// this._createReceivingStream(call, remoteStream)
						console.log("my call is streaming to", key, remoteStream);
					});
				}
			});
		});
	}

	_callPeer(key) {
		this._initStream( (stream) => {
			// initiate call
			if (this.peer && key !== this.state.myPeerID) {
				const call = this.peer.call(key, stream);
				console.log('calling single peer', call.peer, this.peer.connections)
			}

		});
	}

	_initStream(cb) {
		// call another peer
		if (this.state.broadcastStream) {
			cb(this.state.broadcastStream.stream);
		} else {
			this.getUserMedia({
				video: false,
				audio: {
					echoCancellation: true,
					googEchoCancellation: true,
					googNoiseSuppression: true,
					googHighpassFilter: true,
					googTypingNoiseDetection: true
			}}, (stream) => {
				this._createBroadcastingStream(API.getCurrentUUID(), stream);
				cb(stream);

			}, (err) => {
				console.error('Failed to get local stream', err);
			});
		}
	}

	_createBroadcastingStream(call, stream) {
		console.log('creating broadcast', call)

		let broadcastStream = {
			call: call,
			stream: stream
		}

		this.setState({
			broadcastStream
		}, () => {
			console.log("state", this.state.muted)
			this._mute(this.state.muted);
		});

	}

	_createReceivingStream(call, stream) {
		const peerID = call.peer;
		if (peerID === this.state.myPeerID) { return };

		// console.log('creating videoparticipant', call.peer, this.state.videoStreams, this.props.people)
		let videoStreams = this.state.videoStreams;

		videoStreams[peerID] = {
			call: call,
			stream: stream
		}
		console.log("creating new stream", videoStreams, this.state.videoStreams)
		this.setState({videoStreams});
	}

	setSpeakerVolume(ID, userVolume) {
		console.log("set speaker volume", ID, userVolume)
		let speakerVolumes = this.state.speakerVolumes;

		speakerVolumes[ID] = userVolume;
		this.setState({speakerVolumes}, () => {
			this.findLoudestSpeaker();
		});
	}

	findLoudestSpeaker(ID, userVolume) {
		console.log("finding loudest", this.state.speakerVolumes);

		let loudest = Object.keys(this.state.speakerVolumes).reduce( (prev, current) => {
			return (this.state.speakerVolumes[prev] > this.state.speakerVolumes[current]) ? prev : current
		});

		if (this.state.speakerVolumes[loudest] < 0.05) {
			loudest = null;
		}

		console.log("the loudest", loudest)
		if (this.state.speaker !== loudest) {
			this.props.parentSpeakerMethod(loudest);
		}

		this.setState({speaker: loudest})
	}

	_closeChatRoom() {
		console.log("unmount/closing chat view");

		if (this.peer) {
			console.log('destroying peer')
			Object.keys(this.state.videoStreams).map( (key, i) => {
				this.state.videoStreams[key].call.close();
			});
			this.peer.destroy();
		}

		if (this.unsubscribeChatWatch) {
			this.unsubscribeChatWatch();
		}

		API.leaveChat(this.props.id);
	}

    // Setup the `beforeunload` event listener
    beforeUnloadListener = () => {
		window.addEventListener("beforeunload", (ev) => {
			ev.preventDefault();
			return this._closeChatRoom();
		});
	};


}

export default ChatView;
import * as React from "react";

import * as THREE from 'three';
import { ARButton } from 'three/examples/jsm/webxr/ARButton.js';


import Stats from 'stats.js';
import Utils from './Utils.js';

import TeleportControls from './TeleportControls'

import SceneBackground from './SceneBackground.js';
import SceneAvatar from './SceneAvatar.js';
import SceneEnvironment from './SceneEnvironment.js';

import API from "./API.js";
import TWEEN from '@tweenjs/tween.js'

const raycaster = new THREE.Raycaster();
const mouse = new THREE.Vector2();
let moved;

const targetCameraHeight = 160;

class Scene extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			init: false,
			status: null,
			cameraTarget: 0,
			avatars: {},
			faceCamera: true,
			renderedAvatars: {},
			mousePosition: null
		};
	}

	componentDidMount() {
		THREE.Cache.enabled = false;

		this.threeStats = new Stats();
		this.threeStats.dom.id = 'StatsDiv';
		this.threeStats.dom.style.position = null;
		if (this.props.showDebug) {
			this.refs.stats.appendChild(this.threeStats.dom);
			// this.threeStats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
		}

		const width = this.mount.clientWidth
		const height = this.mount.clientHeight

		//ADD SCENE
		this.mixer = null;
		this.clock = new THREE.Clock();

		this.scene = new THREE.Scene();
		SceneEnvironment.init(this.scene);
		console.log("scene ready");

		//ADD CAMERA
		this.camera = new THREE.PerspectiveCamera(35, width / height, 50, 100000);
		// this.camera.position.set(-800, 1400, -1400);
		this.camera.near = 1;
		// this.helper = new THREE.CameraHelper( this.camera );
		// this.scene.add( this.helper );

		// this.camera.position.set( 0, -100, 0 );
		// this.camera.zoom = .001;

		//ADD RENDERER
		this.renderer = new THREE.WebGLRenderer( { alpha: true, antialias: false } );
		// this.renderer.setClearColor('#000000')
		this.renderer.setPixelRatio(window.devicePixelRatio);

		console.log("pixel ratio", window.devicePixelRatio, window.innerWidth, window.innerHeight)
		// this.renderer.setPixelRatio(window.devicePixelRatio/2);
		this.renderer.setSize(width, height);
		this.renderer.xr.enabled = true;
		this.renderer.outputEncoding = THREE.sRGBEncoding;
		this.renderer.shadowMap.enabled = true;
		this.renderer.domElement.id = "ThreeCanvas"
		this.mount.appendChild(this.renderer.domElement)

		// document.body.appendChild( ARButton.createButton( this.renderer, { requiredFeatures: [ 'hit-test' ] } ) );
		// console.log('png', this.renderer.xr)

		// controls
		this.controls = new TeleportControls( this.camera, this.renderer.domElement );

		// init
		this.start()

		// this.goToDollhouse();

		if (this.props.bg) {
			// console.log("props", this.props);

			this.sceneBg = new SceneBackground(this.props.bg);

			this.sceneBg.init((mesh) => {
				this.scene.add(mesh);
			});

			this.controls.autoRotate = true;
			this.controls.autoRotateSpeed = 5;

			this.goToDollhouse();
		}

		// add resize
		window.addEventListener( 'resize', this.onWindowResize, false );
	}

	componentDidUpdate(prevProps, prevStates) {
		// console.log(this.props.people, prevProps.people)
		if (this.props.people !== prevProps.people && this.props.people) {
			// console.log("people change props", this.props.people);

			Object.keys(this.state.renderedAvatars).map(this._removeSceneAvatar);
			Object.keys(this.props.people).map(this._createSceneAvatar);

			// this is really good auto camrea to auto fit all peopel that join
			if (Object.keys(this.props.people).length > 1) {
				this.goToThirdPerson();
				this.goToDollhouse(Object.keys(this.props.people).length);
			} else {
				this.goToSecondPerson(true);
			}
		}

		if (this.state.status !== prevStates.status && this.state.status) {
			this.props.parentStatusMethod(this.state.status);
		}

		if (this.props.showDebug !== prevProps.showDebug) {
			const span = document.getElementById("StatsDiv");

			if (this.threeStats.dom.contains(span)) {
				this.refs.stats.removeChild(this.threeStats.dom);
			} else {
				this.refs.stats.appendChild(this.threeStats.dom);
			}
		}

		if (this.props.match.params.type !== prevProps.match.params.type) {
			// console.log("fart", Object.keys(this.props.people).length)

			// if (this.props.match.params.type === "chat" && Object.keys(this.props.people).length > 1) {
			// 	console.log("go to dollhouse", Object.keys(this.props.people).length)
			// 	this.goToDollhouse(Object.keys(this.props.people).length);
			// } else {
			// 	this.goToPortrait();
			// }

		}
	}

	componentWillUnmount() {
		console.log('scene unmounting');
		// this.stop();
	}

	_createSceneAvatar = (key, i, array) => {
		let avatars = this.state.avatars;
		let renderedAvatars = this.state.renderedAvatars;
		// console.log("create scene avatar", key, this.state.avatars)
		if (!this.state.avatars[key]) {
			// if it doesn't exist, create it and add to scene
			// console.log("creating avatar", key)
			avatars[key] = new SceneAvatar(this.props.people[key], this.props.parentStatusMethod);

			avatars[key].init( (mesh) => {
				avatars[key].mesh = mesh;
				this.scene.add(avatars[key].mesh);

				renderedAvatars[key] = avatars[key];
				this.setState({
					avatars: avatars,
					renderedAvatars: renderedAvatars
				});

				this.repositionAvatar(avatars[key], i, array.length);
				this.props.parentStatusMethod("loading yo face", "fadePulse");
			});
		} else {
			// if it already exists in avatars, just add it to the scene and renderedAvatars
			if (this.state.avatars[key].mesh && !this.state.renderedAvatars[key]) {
				this.scene.add(this.state.avatars[key].mesh);
				renderedAvatars[key] = this.state.avatars[key];

				this.setState({
					renderedAvatars: renderedAvatars
				}, () => {
					this.repositionAvatar(this.state.renderedAvatars[key], i, array.length);
				});
			} else if (this.state.renderedAvatars[key]) {
				this.repositionAvatar(this.state.renderedAvatars[key], i, array.length);
			}
		}
	}

	_removeSceneAvatar = (key, i) => {
		// if this avatar doesn't exist in the new people state, get rid of it
		// or if I don't exist
		// console.log("remove scene avatar", this.props.people[key], this.state.renderedAvatars[key])
		if (!this.props.people[key] || !this.props.people[API.getCurrentUUID()]) {
			// console.log("removing avatar", this.state.renderedAvatars[key])
			this.scene.remove( this.state.renderedAvatars[key].mesh );

			// reset avatars
			let renderedAvatars = this.state.renderedAvatars;
			delete renderedAvatars[key];
			this.setState({ renderedAvatars });
		}
	}

	toggleMovement = (allowMovement) => {
		this.controls.addEventListener( 'change', this.handleCameraMovement ); // call this only in static scenes (i.e., if there is no animation loop)

		if (allowMovement) {
			this.goToThirdPerson();
		} else {
			this.goToSecondPerson();
		}
	}

	handleCameraMovement = (e) => {
		if (this.state.renderedAvatars[API.getCurrentUUID()]) {
			// console.log("camera controls changing", this.controls.relativeDistance())
			this.controls.target.y = Utils.mapRange(this.controls.distance(), this.controls.minDistance, this.controls.maxDistance, targetCameraHeight, 0);
			this.camera.fov = Utils.mapRange(this.controls.distance(), this.controls.minDistance, this.controls.maxDistance, 35, 50);
			// this.camera.near = Utils.mapRange(this.controls.distance(), this.controls.minDistance, this.controls.maxDistance, .1, 1000);
			this.camera.updateProjectionMatrix();
			// console.log(this.camera.position, this.controls.target)
		}
	};

	roman = (e) => {
		// this.controls.enabled = false;
		// this.state.renderedAvatars[API.getCurrentUUID()].mesh.rotation.y = targetPosition.y;
		// console.log(this.state.renderedAvatars[API.getCurrentUUID()])
		if (this.state.renderedAvatars[API.getCurrentUUID()]) {

			const child = this.state.renderedAvatars[API.getCurrentUUID()];
			var rotation = new THREE.Vector3().copy( child.neck.rotation );

			var position = new THREE.Vector3().copy( child.neck.position );
			// this.camera.position = child.position;
			// child.neck.localToWorld( position );
			this.camera.localToWorld( position );

			// this.camera.parent = child.neck
			// this.camera.scale.set(0);
			// this.camera.lookAt(new THREE.Vector3(0, -2000, 0));
			this.camera.position.set( new THREE.Vector3(0, 0, 100) );
			// this.camera.rotation.set( -rotation.x, -rotation.y, -rotation.z );

			// this.camera.position.set(child.neck.position)
			// console.log('png', child.neck.rotation.y, child.getNeckPosition().y )

			// this.camera.zoom = 19999900
			// this.camera.lookAt( child.neck.position );

			// this.camera.rotation.set(child.rotation);
			// console.log(child.getNeckPosition())

			// this.camera.zoom = .002000;
			// this.camera.lookAt( position );
			// const cameraPosition = new THREE.Vector3(-100, 200, 0);

			// this._tweenCamera(cameraPosition, position, 100)


			// this.camera.lookAt(this.state.renderedAvatars[API.getCurrentUUID()].hair.position)
			// this.camera.parent()
			// THREE.SceneUtils.detach( child, parent, scene );
			// THREE.SceneUtils.attach( this.camera, this.scene, this.state.renderedAvatars[API.getCurrentUUID()].neck );

		}

	}

	repositionAvatar(avatarData, i, length) {
		// position avatars so they don't overlap
		// console.log("reposition avatar mesh", avatarData, i, length);
		const avatarShoulderWidth = 120;

		let radius = Math.max(50, (avatarShoulderWidth * length)/(2 * Math.PI));

		// if just 1 person set radius to 0
		if (length === 1) {
			radius = 0
		}

		let rotation = ((2 * Math.PI) * (i/length));

		avatarData.mesh.rotation.y = Math.PI - rotation + (Math.PI/2);

		avatarData.mesh.position.x = Math.cos(rotation) * radius;
		avatarData.mesh.position.z = Math.sin(rotation) * radius;
	}

	start = () => {
		if (!this.frameId) {
			this.frameId = requestAnimationFrame(this.animate)
		}
	}

	stop = () => {
		cancelAnimationFrame(this.frameId)

		if (this.renderer.domElement) {
			this.mount.removeChild(this.renderer.domElement)
		}
	}

	animate = () => {
		if (this.props.showDebug) {
			this.threeStats.begin();
		}
		// this.roman();
		this.controls.update(); // only required if controls.enableDamping = true, or if controls.autoRotate = true
		this.controls.updateOrientation();
		// update mixer for glb animations
		let delta = this.clock.getDelta();

		if (this.sceneBg) {
			this.sceneBg.updateMixer(delta);
		}

		// update tween/mixer animations
		if (this.state.renderedAvatars) {
			Object.keys(this.state.renderedAvatars).map((key, i, array) => {
				// this.state.renderedAvatars[key].animateFace(this.clock.oldTime);
				return this.state.renderedAvatars[key].updateAnimations(delta);
			});
		}

		TWEEN.update();

		this.renderScene();

		if (this.props.showDebug) {
			// monitored code goes here
			this.threeStats.end();
		}

		this.frameId = this.renderer.setAnimationLoop(this.animate);
	}

	renderScene = () => {
		this.renderer.render(this.scene, this.camera);
	}

	onWindowResize = () => {
		// console.log("this mount", this.mount, this.mount.clientWidth, this.mount.clientHeight);
		if (this.mount) {
			var width = this.mount.clientWidth;
			var height = this.mount.clientHeight;

			this.camera.aspect = width / height;
			this.camera.updateProjectionMatrix();

			this.renderer.setSize( width, height );
		}
	}

	render(){
		return(
				<div
					className="full"
					ref={(mount) => { this.mount = mount }}
					onMouseDown={this.getMouseDown.bind(this)}
					onMouseUp={this.getMouseUp.bind(this)}
					onMouseMove={this.getMouseMove.bind(this)}
					onMouseLeave={this.resetMousePosition.bind(this)}
				>
					<div
						ref="stats"
						className="fixed left-0 bottom-0 mb2 ml2"
					>
					</div>
				</div>
		)
	}

	resetMousePosition = (event) => {
		this.setState({mousePosition: null})
	}

	getMouseDown = (event) => {
		moved = false;
	}

	getMouseUp = (event) => {
		if (moved) {
			// console.log('moved / dragged')
		} else {
			console.log('click')
			// update the picking ray with the camera and mouse position
			raycaster.setFromCamera( mouse, this.camera );

			// calculate objects intersecting the picking ray
			const intersects = raycaster.intersectObjects( this.scene.children );

			if (intersects[0]) {
				let targetPosition = intersects[0].point
				// const targetPosition = new THREE.Vector3(-0, 350, 350);
				let cameraPosition = new THREE.Vector3(this.controls.target.x, this.camera.position.y, this.controls.target.z)

				// console.log([this.camera.position, this.controls.target], [cameraPosition, targetPosition])
				// console.log(this.camera.position, this.controls.target)

				//ADD CUBE
				const boxGeometry = new THREE.BoxGeometry(20, 20, 20);
				const boxMaterial = new THREE.MeshNormalMaterial();
				let cube = new THREE.Mesh(boxGeometry, boxMaterial);
				cube.position.set( targetPosition.x, 0, targetPosition.z );

				let adjacent = targetPosition.z - this.controls.target.z
				let opposite = targetPosition.x - this.controls.target.x

				let angle = Math.atan(opposite / adjacent);

				cube.rotateY( angle );
				// cube.rotation.set(angle, angle, angle)
				console.log(angle)
				this.scene.add( cube );

				this._tweenCamera( cameraPosition, targetPosition, 1000 );
			}

		}


		// for ( let i = 0; i < intersects.length; i ++ ) {
		// 	intersects[ i ].object.material.color.set( 0xff0000 );
		// }
	}

	getMouseMove = (event) => {
		moved = true

		const mousePosition = {
			x: Utils.modulate(event.screenX, [0, this.mount.clientWidth], [-1, 1], true),
			y: Utils.modulate(event.screenY, [0, this.mount.clientHeight], [-1, 1], true)
		};

		mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
		mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;

		this.setState({mousePosition})
	}

	setAvatarHeadPosition = (data) => {
		const myAvatar = this.state.renderedAvatars[API.getCurrentUUID()];
		data.mousePosition = this.state.mousePosition;

		if (myAvatar) {
			// console.log("my position", new THREE.Vector3().copy(myAvatar.mesh.position));
			const targetRotation = this.state.faceCamera ? new THREE.Vector3(0, this.camera.rotation.y, 0) : new THREE.Vector3(0, this.camera.rotation.y + Math.PI, 0);

			data.global = {
				position: {
					x: this.controls.target.x,
					y: 0,
					z: this.controls.target.z
				},
				rotation: {
					x: targetRotation.x,
					y: targetRotation.y,
					z: targetRotation.z
				}
			}

			// console.log("peeps", Object.keys(this.props.people).length)
			// console.log("me", this.controls.getAzimuthalAngle());
			// only sync if there's more than 1 dude
			if (Object.keys(this.props.people).length > 1) {
				API.setCurrentAvatarMovement(data);
			}

			myAvatar.animateFace(data);
		}
	}

	goToIntro = () => {
		console.log('going intro')
		this.setState({ faceCamera: false })
		// this.camera.position.set(1000, 1000, -1000);
		this.camera.position.set(-800, 1400, -1400);

		let cameraPosition = new THREE.Vector3(-60, 160, 60);
		let targetPosition = this.state.renderedAvatars[API.getCurrentUUID()] ? new THREE.Vector3(this.state.renderedAvatars[API.getCurrentUUID()].mesh.position.x, targetCameraHeight, this.state.renderedAvatars[API.getCurrentUUID()].mesh.position.z) : new THREE.Vector3(0, targetCameraHeight, 0);

		this._tweenCamera( cameraPosition, targetPosition, 2000 );
	}

	goToSecondPerson = (init) => {
		console.log('going second', init)
		this.setState({ faceCamera: false })
		let	cameraPosition = new THREE.Vector3(-this.camera.position.x, 220, -this.camera.position.z)

		if (init) {
			cameraPosition = new THREE.Vector3(-350, 350, 350);
		}
		// let cameraPosition = new THREE.Vector3(350, 220, 0);

		let targetPosition = this.controls.target;

		this._tweenCamera( cameraPosition, targetPosition, 2000 );
	}

	goToThirdPerson = () => {
		console.log('going third')
		this.setState({ faceCamera: false })

		let cameraPosition = new THREE.Vector3(-60, 160, 60);
		let targetPosition = this.state.renderedAvatars[API.getCurrentUUID()] ? new THREE.Vector3(this.state.renderedAvatars[API.getCurrentUUID()].mesh.position.x, targetCameraHeight, this.state.renderedAvatars[API.getCurrentUUID()].mesh.position.z) : new THREE.Vector3(0, targetCameraHeight, 0);

		this._tweenCamera( cameraPosition, targetPosition, 2000 );
	}

	goToPortrait = () => {
		console.log('going to portrait');
		// this.state.faceCamera
		this.setState({ faceCamera: true })
		const cameraPosition = new THREE.Vector3(-200, 200, 0);
		const targetPosition = this.state.renderedAvatars[API.getCurrentUUID()] ? new THREE.Vector3(this.state.renderedAvatars[API.getCurrentUUID()].mesh.position.x, targetCameraHeight, this.state.renderedAvatars[API.getCurrentUUID()].mesh.position.z) : new THREE.Vector3(0, targetCameraHeight, 0);
		const duration = 2000;

		this._tweenCamera( cameraPosition, targetPosition, duration );
	}

	goToDollhouse = (length) => {
		console.log('going to dollhouse', length);

		if (!length) {
			if (this.state.renderedAvatars) {
				length = Object.keys(this.state.renderedAvatars).length;
			} else {
				length = 10;
			}
		}

		if (length === 1) {
			this.goToPortrait();
		} else {
			const avatarShoulderWidth = 80;
			let radius = Math.max(200, (avatarShoulderWidth * length*3)/(2 * Math.PI));

			const cameraAvatarPosition = new THREE.Vector3( radius, radius + 40, radius );
			const targetAvatarPosition = new THREE.Vector3( 0, targetCameraHeight, 0 );

			const cameraPosition = new THREE.Vector3().copy(cameraAvatarPosition);
			const targetPosition = new THREE.Vector3().copy(targetAvatarPosition);
			const duration = 2000;

			this._tweenCamera( cameraPosition, targetPosition, duration );
		}
	}

	goToAvatar = (avatarData) => {
		console.log("go to avatar")
		if (!avatarData) {return};

		const distance = 120;
		const height = 148;
		const rotation = avatarData.mesh.rotation.y;

		// todo, this doesn't really line up against people's faces
		const cameraAvatarPosition = new THREE.Vector3( avatarData.mesh.position.x + Math.sin(rotation) * distance, avatarData.mesh.position.y + height + 25, avatarData.mesh.position.z + Math.cos(rotation) * distance);
		const targetAvatarPosition = new THREE.Vector3( avatarData.mesh.position.x, avatarData.mesh.position.y + height, avatarData.mesh.position.z );

		// console.log('going', camera.position);
		const cameraPosition = new THREE.Vector3().copy( cameraAvatarPosition );
		const targetPosition = new THREE.Vector3().copy( targetAvatarPosition );
		const duration = 2000;

		this._tweenCamera( cameraPosition, targetPosition, duration );
	}

	goToSpeaker = (ID) => {
		// get index from ID
		console.log("go to speaker", ID)
		if (!ID) {
			console.log('go to dollhouse if this is null')

			setTimeout( () => {
				this.goToDollhouse()
			}, 2000);

		} else {
			let i = Object.keys(this.state.renderedAvatars).indexOf(ID);

			this.goToAvatar(this.state.renderedAvatars[ID]);
			this.setState({cameraTarget: i})
		}
	}

	goToNextSpeaker = () => {
		if ( Object.keys(this.state.renderedAvatars).length !== 0) {
			let targetSpeakerID;
			let i = this.state.cameraTarget;
			let length = Object.keys(this.state.renderedAvatars).length;

			if (this.state.cameraTarget < length-1) {
				i= i+1
			} else {
				i=0
			}

			targetSpeakerID = Object.keys(this.state.renderedAvatars)[i]
			// console.log("array", this.state.cameraTarget, i, length);

			this.goToAvatar(this.state.renderedAvatars[targetSpeakerID]);
			this.setState({cameraTarget: i})
		}
	}

	toggleBackground = (event) => {
		SceneEnvironment._updateBG(this.scene);
	}

	toggleOrientation = (event) => {
		if (this.controls) {
			if (!this.controls.enabledOrientation) {
				this.controls.connectOrientation();
			} else {
				this.controls.disconnectOrientation();
			}
			// is nice to turn bg black for nreal
			// this.scene.background = new THREE.Color( 0x000000 );
		}
	}

	_tweenCameraRotation(targetQuaternion, duration){
		// const targetOrientation = new THREE.Quaternion().set(0, 0, 0, 1).normalize();

		// gsap.to({}, {
		//     duration: 2,
		//     onUpdate: function() {
		//         camera.quaternion.slerp(targetOrientation, this.progress());
		//     }
		// });
		const cameraQuaternion = new THREE.Quaternion().copy( this.camera.quaternion );

	    new TWEEN.Tween( cameraQuaternion )
	    	.to(targetQuaternion, duration)
	        .easing( TWEEN.Easing.Quadratic.InOut )
	        .onUpdate((progress) => {
	            this.camera.quaternion.slerp(targetQuaternion, cameraQuaternion)
				// this.camera.rotation.setFromQuaternion( qm );
	    })
	    .start();
	}

	_tweenCamera = ( newCameraPosition, newTargetPosition, duration ) => {
		// this.controls.enabled = false;

		const cameraPosition = new THREE.Vector3().copy( this.camera.position );
		const targetPosition = new THREE.Vector3().copy( this.controls.target );

		// console.log([cameraPosition, newCameraPosition, targetPosition, newTargetPosition, duration])

		new TWEEN.Tween( cameraPosition )
			.to( newCameraPosition, duration )
			.easing( TWEEN.Easing.Exponential.Out )
			.onUpdate(() => {
				// console.log('tweening');
				this.camera.position.copy( cameraPosition );
			})
			.onComplete(() => {
				this.camera.position.copy( newCameraPosition );
			})
			.start();

		new TWEEN.Tween( targetPosition )
			.to( newTargetPosition, duration )
			.easing( TWEEN.Easing.Exponential.Out )
			.onUpdate(() => {
				this.camera.lookAt( targetPosition );
				this.controls.target.x = targetPosition.x
				this.controls.target.y = Utils.mapRange(this.controls.distance(), this.controls.minDistance, this.controls.maxDistance, targetCameraHeight, 0);
				this.controls.target.z = targetPosition.z
				this.controls.update();
			} )
			.onComplete(() => {
				// this.camera.lookAt( newTargetPosition );
				// this.controls.target = newTargetPosition;
				// this.controls.enabled = true;
				// this.controls.target.y = Utils.mapRange(this.controls.distance(), this.controls.minDistance, this.controls.maxDistance, targetCameraHeight, 0);
				this.controls.update();
			} )
			.start();
	}
}

export default Scene;

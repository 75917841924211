// https://css-tricks.com/intro-firebase-react/
import Firebase from 'firebase/app';

require("firebase/auth");
require("firebase/database");
require("firebase/firestore");
// require("firebase/messaging");
// require("firebase/functions");

const config = {
    apiKey: "AIzaSyBbl92sY-TkN7u5dzc0IqKhrSN0_Y-SfYI",
    authDomain: "teleport-ec7bd.firebaseapp.com",
    databaseURL: "https://teleport-ec7bd.firebaseio.com",
    projectId: "teleport-ec7bd",
    storageBucket: "teleport-ec7bd.appspot.com",
    messagingSenderId: "461340463547",
    appId: "1:461340463547:web:dd54b33a3f7e99f796749e",
    measurementId: "G-NJ8X79NJM5"

	// clientId: "590949159585-2p4jn8qstnnc3ink76cjtiqrbfqk8qv8.apps.googleusercontent.com",
	// scopes: [
	// 	"email",
	// 	"profile"//,
	// 	// "https://www.googleapis.com/auth/calendar.events"
	// ]
};

Firebase.initializeApp(config);

export const Provider = new Firebase.auth.GoogleAuthProvider();
// Provider.addScope('https://www.googleapis.com/auth/calendar.events');

export const Auth = Firebase.auth();

// this will break the app if Messages is init on browsers that don't support push(safari, ios)
// check if messaging.isSupported before initing messaging
// https://medium.freecodecamp.org/how-to-add-push-notifications-to-a-web-app-with-firebase-528a702e13e1
// export const Messaging = Firebase.messaging.isSupported() ? Firebase.messaging() : null;

export const Database = Firebase.firestore();
export const RealtimeDatabase = Firebase.database();

export const Timestamp = Firebase.firestore.Timestamp;
export const FieldValue = Firebase.firestore.FieldValue;


export const GeoPoint = Firebase.firestore.GeoPoint;

// enable persistence
// Database.enablePersistence().catch(function(err) {
// 	if (err.code === 'failed-precondition') {
// 		console.log("Multiple tabs open, persistence can only be enabled in one tab at a a time.", err);
// 	} else if (err.code === 'unimplemented') {
// 		console.log("// The current browser does not support all of the features required to enable persistence", err);
// 	}
// });

// Database.disableNetwork();
export default Firebase;
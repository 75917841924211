import * as React from 'react';

class BackButton extends React.Component {
	render() {
		return (
			<button
				id="backButton"
				className="z2 white hover"
				onClick={this._handleClick.bind(this)}
			>
				<i className={`absolute material-icons medium scaleIn ${this.props.color} ${this.props.show ? "scaleIn" : "scaleOut"}`}>keyboard_backspace</i>
				<i className={`material-icons medium scaleIn ${this.props.color} ${!this.props.show ? "scaleIn" : "scaleOut"}`}>waves</i>
			</button>
		);
	}

	_handleClick() {
		this.props.onClick();
	}
}

BackButton.defaultProps = {
	show: true
};

export default BackButton;

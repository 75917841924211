import React from 'react';

import { createPortal } from "react-dom";
import { withRouter } from "react-router-dom";

// components
import API from './API.js';
import Utils from './Utils.js';

// using this: https://github.com/justadudewhohacks/face-api.js/
import * as faceapi from 'face-api.js';

class Selfie extends React.Component {

	constructor(props)
	{
		super(props);

		this.state ={
			flip: true,
			cameraSize: {
				width: 128,
				height: 128
			}
		};

		this.bgCameraElement = document.createElement('div');
		this.bgCameraElement.className = "full absolute top-0 zn1"
	}

	componentDidMount() {
		this.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;

		API.getCurrentUser(currentUserData => {
			let newPeople = {};
			newPeople[currentUserData.uid] = currentUserData;

			this.props.parentUserMethod(newPeople);
		});

		this.faceInterval = null;

		Promise.all([
			faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
			// faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
			faceapi.nets.faceLandmark68TinyNet.loadFromUri('/models'),
			// faceapi.nets.faceRecognitionNet.loadFromUri('/models'),
			faceapi.nets.faceExpressionNet.loadFromUri('/models')
		]).then(this._startVideo)

		this.videoFrontFacing.addEventListener('play', this._detectFace);

		const sceneRoot = document.getElementById('scene');
	    sceneRoot.appendChild(this.bgCameraElement);
	}

	componentDidUpdate(prevProps, prevStates) {
		if (this.props.rearFacingCamera !== prevProps.rearFacingCamera && this.props.rearFacingCamera ) {
			// this._startRearFacingCamera(); //disabling now because i can only grab one camera
		}
	}

	componentWillUnmount() {
		console.log("unmount selfie");

		this.videoFrontFacing.removeEventListener('play', this._detectFace);
		if (this.faceInterval) {
			clearInterval(this.faceInterval);
		}
	}

	render() {

		return (
			<div
				className={`flex items-center justify-center`}
			>
				<div
					className={`flex flex-column videoParticipantContainer shadowLarge ${this.state.flip ? "flip" : ""}`}
				>
					<video
						ref={(video) => { this.videoFrontFacing = video }}
						className="videoParticipant"

						autoPlay
						playsInline
						width={this.state.cameraSize.width}
						height={this.state.cameraSize.height}
					/>

				</div>

				{
					createPortal(
						<video
							ref={(video) => { this.videoRearFacing = video }}
							className="videoParticipant"

							autoPlay
							playsInline
							width={this.state.cameraSize.width}
							height={this.state.cameraSize.height}
						/>
						, this.bgCameraElement
					)
				}
			</div>
		);
	}

	_startVideo = () => {
		const devices = navigator.mediaDevices.enumerateDevices();

		devices.then( (devices) => {
			console.log('devices', devices)
		}, (error) => {
			console.log('devices', error)
		});


		if (this.getUserMedia) {

			// get front
			this.getUserMedia({
				audio: false,
				video: {
					// deviceId: "default",
				    facingMode: 'user',
					width: {ideal: this.state.cameraSize.width},
					height: {ideal: this.state.cameraSize.height}
				}
			}, (stream) => {
				// console.log('stream', stream)
				this.videoFrontFacing.srcObject = stream;
			}, (err) => {
				console.error("error", err)
			});
		}

	}

	_startRearFacingCamera = () => {
		if (this.getUserMedia) {

			// get back
			this.getUserMedia({
				video: {
					// deviceId: "default",
					facingMode: {
					exact: 'environment' //this won't fallback to anything else
					},
					width: {ideal: 1280},
					height: {ideal: 1280}
				}
			}, (stream) => {
				this.videoRearFacing.srcObject = stream;
			}, (err) => {
				console.error("error", err)
			});
		}
	}

	reset = () => {
		API.getCurrentUser(currentUserData => {
			let newPeople = {};
			newPeople[currentUserData.uid] = currentUserData;

			this.props.parentUserMethod(newPeople);
		});
	}

	_detectFace = () => {
		const canvas = faceapi.createCanvasFromMedia(this.videoFrontFacing)
		canvas.id="canvas";
		canvas.className="absolute canvasContainer";

		this.videoFrontFacing.insertAdjacentElement("beforebegin", canvas);

		const displaySize = { width: this.videoFrontFacing.width, height: this.videoFrontFacing.height }
		faceapi.matchDimensions(canvas, displaySize)

		this.faceInterval = setInterval(async () => {
			// this is the tiny model
			const useTinyModel = true;
			const detection =
				await faceapi.detectSingleFace(
					this.videoFrontFacing,
					new faceapi.TinyFaceDetectorOptions({
						inputSize: this.state.cameraSize.width,
						scoreThreshold: 0.4
					})
				)
				.withFaceLandmarks(useTinyModel)
				.withFaceExpressions();

			// this is the normal model
			// const detection = await faceapi.detectSingleFace(this.video, new faceapi.TinyFaceDetectorOptions({ inputSize: this.state.cameraSize.width })).withFaceLandmarks().withFaceExpressions()

			// on single face detection returns undefined if it can't find you
			// console.log("detection", detection)
			if (!detection) return;

			canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height)

			if (this.props.showDebug) {
				const resizedDetection = faceapi.resizeResults(detection, displaySize)

				faceapi.draw.drawDetections(canvas, resizedDetection)
				faceapi.draw.drawFaceLandmarks(canvas, resizedDetection)
				faceapi.draw.drawFaceExpressions(canvas, resizedDetection)
			}



			const landmarks = detection.landmarks;
			let landmarkPositions = {};

			// only available for 68 point face ladnamrks (FaceLandmarks68)
			landmarkPositions.jaw = landmarks.getJawOutline();
			landmarkPositions.nose = landmarks.getNose();
			landmarkPositions.mouth = landmarks.getMouth();
			landmarkPositions.leftEye = landmarks.getLeftEye();
			landmarkPositions.rightEye = landmarks.getRightEye();
			landmarkPositions.leftEyeBrow = landmarks.getLeftEyeBrow();
			landmarkPositions.rightEyeBrow = landmarks.getRightEyeBrow();

			// format for 3d
			const bounds = {x: [0, detection.detection.imageWidth], y: [0, detection.detection.imageHeight]};
			const mapping = this.state.flip ? [1, -1] : [-1, 1];
			const blendshapeMapping = this.state.flip ? [1, 0] : [0, 1];

			let head = {
				position: {},
				rotation: {},
				blendshapes: {},
				expressions: {}
			};

			// console.log('box', detection.detection.box.left, detection.alignedRect.box.left)

			head.position.x = Utils.modulate(detection.detection.box.left + (detection.detection.box.width/2), bounds.x, mapping);
			head.position.y = Utils.modulate(detection.detection.box.top + (detection.detection.box.height/2), bounds.y, mapping);
			// head.position.z = Utils.modulate(detection.detection.box.width, [detection.detection.imageWidth*.75, 0], mapping, false);

			head.position.z = Utils.modulate(
				landmarkPositions.jaw[landmarkPositions.jaw.length-1].x - landmarkPositions.jaw[0].x,
				[detection.detection.imageWidth*.75, 0],
				mapping,
				false
			);

			// xRotation based on nose distance to mouth and eyes
			head.rotation.x = Utils.modulate(landmarkPositions.nose[3].y, [Utils.getMaxY(landmarkPositions.mouth), landmarkPositions.leftEyeBrow[0].y], mapping);
			// yRotation based off of euclid distance of nose to edge, probably shoudl do jaw
			head.rotation.y = Utils.modulate(landmarkPositions.nose[0].x, [detection.detection.box.left, detection.detection.box.right], mapping);
			// zRotation based off of tangent of both eyes
			head.rotation.z = Math.atan((landmarkPositions.leftEye[0].y - landmarkPositions.rightEye[0].y)/(landmarkPositions.leftEye[0].x - landmarkPositions.rightEye[0].x));

			// https://developer.apple.com/documentation/arkit/arfaceanchor/blendshapelocation
			head.expressions = detection.expressions;
			// head.blendshapes.eyeBlinkRight = Utils.modulate(landmarkPositions.rightEye[0].y, [landmarkPositions.rightEye[0].y, landmarkPositions.rightEye[1].y], [0, 1]);

			// roll mouth left and right
			// head.blendshapes.mouthLeft = Utils.modulate(Utils.getMaxX(landmarkPositions.mouth), [landmarkPositions.nose[0].x, Utils.getMaxX(landmarkPositions.jaw)], [0, 1]);
			// console.log("mouth", head.blendshapes.mouthLeft)
			// head.blendshapes.mouthRight = Utils.modulate(Utils.getMinX(landmarkPositions.mouth), [landmarkPositions.nose[4].x, Utils.getMinX(landmarkPositions.jaw)], mapping);

			// sides of mouth get smaller and stay closed at center
			head.blendshapes.mouthPucker = Utils.modulate(
				Utils.pointDistance( landmarkPositions.mouth[0], landmarkPositions.mouth[6]),
				// [ Utils.pointDistance( Utils.getMidPoint(landmarkPositions.rightEye), Utils.getMidPoint(landmarkPositions.leftEye) ), Utils.pointDistance( landmarkPositions.nose[4], landmarkPositions.nose[8]) ],
				[ Utils.pointDistance( landmarkPositions.leftEye[3], landmarkPositions.rightEye[0]) * 1.25, Utils.pointDistance( landmarkPositions.leftEye[3], landmarkPositions.rightEye[0]) * 1.15 ],
				[0, 1]
			);

			// console.log(head.blendshapes.mouthPucker, Utils.pointDistance( landmarkPositions.mouth[0], landmarkPositions.mouth[6]), Utils.pointDistance( landmarkPositions.leftEye[3], landmarkPositions.rightEye[0]) * 1.25, Utils.pointDistance( landmarkPositions.leftEye[3], landmarkPositions.rightEye[0]) )

			// console.log(landmarkPositions.leftEye, landmarkPositions.rightEye)
			// sides of mouth get closer and sides of mouth get more open
			// head.blendshapes.mouthFunnel = Utils.modulate(
			// 	landmarkPositions.mouth[17].y - landmarkPositions.mouth[14].y,
			// 	[Utils.getMaxY(landmarkPositions.leftEye) - Utils.getMinY(landmarkPositions.leftEye), (Utils.getMaxY(landmarkPositions.jaw) - Utils.getMaxY(landmarkPositions.nose)) / 2],
			// 	[0, 1]
			// );


			head.blendshapes.jawOpen = Utils.modulate(
				landmarkPositions.mouth[17].y - landmarkPositions.mouth[14].y,
				[Utils.getMaxY(landmarkPositions.leftEye) - Utils.getMinY(landmarkPositions.leftEye), (Utils.getMaxY(landmarkPositions.jaw) - Utils.getMaxY(landmarkPositions.nose)) / 2],
				[0, 1]
			);
			// console.log("mouth", head.blendshapes.jawOpen, landmarkPositions.mouth[17] - landmarkPositions.mouth[14], );
			// console.log(head.blendshapes.jawOpen)

			// head.blendshapes.leftEyeBrow = Utils.modulate(landmarkPositions.leftEyeBrow[4].y, [landmarkPositions.leftEyeBrow[0].y, detection.detection.box.top], [0, 1], true)*2;
			// head.blendshapes.rightEyeBrow = Utils.modulate(landmarkPositions.rightEyeBrow[0].y, [landmarkPositions.rightEyeBrow[4].y, detection.detection.box.top], [0, 1], true)*2;

			// head.blendshapes.rightEyeBrow = Utils.modulate(Utils.getMinY(landmarkPositions.rightEyeBrow), [landmarkPositions.rightEyeBrow[0].y, detection.detection.box.top], [0, 1]);
			// console.log(landmarkPositions.leftEyeBrow, landmarkPositions.rightEyeBrow, head.blendshapes.leftEyeBrow, detection.detection.box.top)
			// console.log(head.blendshapes.leftEyeBrow, landmarkPositions.leftEyeBrow[0].y, landmarkPositions.leftEyeBrow[4].y, detection.detection.box.top)

			// console.log(Utils.getMaxY(landmarkPositions.mouth) - Utils.getMinY(landmarkPositions.mouth))
			// console.log(head.blendshapes.rightEyeBrow, Utils.getMinY(landmarkPositions.leftEyeBrow), detection.detection.box.top)

			// console.log("detection", head.blendshapes.jawOpen)
			// console.log("detection", detection.expressions);
			// console.log("landmarkPositions", zRotation)


			// animate avatar and send points to firebase
			if (this.props.sceneRef) {
				this.props.sceneRef.setAvatarHeadPosition(head);
			}

		}, 40)
	}
}

export default withRouter(Selfie);
// import { Database } from './Firebase';
import * as THREE from 'three';
import AvatarSDK from './AvatarSDK.js';
import Utils from './Utils.js';
import TWEEN from '@tweenjs/tween.js'
import API from "./API.js";

import male_idle from '../res/male-idle5.glb'
import female_idle from '../res/female-idle.glb'

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

const tweenDuration = 1600;

class SceneAvatar {
	constructor(personData, statusMethod) {
		this.personData = personData || null;

		this.avatarHead = null;
		this.defaultHead = null;

		// meshes
		this.body = null;
		this.shirt = null;

		// bones
		this.hips = null;
		this.spine = null;
		this.neck = null;
		this.head = null;

		this.skin = null;
		this.skinColor = null;

		this.hair = null;
		this.hairColor = null;

		this.gltf = null;
		this.mixer = null;

		this.statusMethod = statusMethod || null;

		// animations
		this.tweenGroup = new TWEEN.Group();
		this.eyeBlink = null;
	}

	init(cb) {
		// todo: move this to its own component
		if (this.personData.avatarCode && this.personData.playerUID) {
			AvatarSDK.getAvatar(this.personData.avatarCode, this.personData.playerUID, (meshData) => {
				// console.log("got baseMesh", meshData, this.defaultHead);
				this.statusMethod("got yo face", "scaleOut delay");

				this.avatarHead = meshData;
				this.neck.add(this.avatarHead);

				this._hideObject(this.defaultHead);
			});

			AvatarSDK.getAvatarModelInfo(this.personData.avatarCode, this.personData.playerUID, (modelInfo) => {
	            // console.log("avatar model info", this.skin, modelInfo.skin_color, modelInfo);

				this.skinColor = `rgb(${modelInfo.skin_color.red}, ${modelInfo.skin_color.green}, ${modelInfo.skin_color.blue})`;
				// console.log("skin color", this.skinColor)

				if (this.skin) {
					// helpful material guide https://threejsfundamentals.org/threejs/lessons/threejs-materials.html
					// console.log('setting skinColor', this.skinColor, this.skin.material)
					this.skin.material.map = null;
					this.skin.material.color.set( this.skinColor );
					this.skin.material.needsUpdate = true;
				}


				this.hairColor = `rgb(${modelInfo.hair_color.red}, ${modelInfo.hair_color.green}, ${modelInfo.hair_color.blue})`;
				// console.log("skin color", this.skinColor)

				if (this.hair) {
					// helpful material guide https://threejsfundamentals.org/threejs/lessons/threejs-materials.html
					// console.log('setting skinColor', this.skinColor, this.skin.material)
					this.hair.material.map = null;
					this.hair.material.color.set( this.hairColor );
					this.hair.material.needsUpdate = true;
				}
			});
		}

		// use firebase cache for gender detection
		// console.log("has data", this.personData.modelInfo)
		if (this.personData.modelInfo) {
			this.personData.modelInfo.gender === "female" ? this._loadGLTF(female_idle, cb) : this._loadGLTF(male_idle, cb);
		} else {
			this._loadGLTF(male_idle, cb);
		}

		if (this.personData.uid !== API.getCurrentUUID()) {
			API.watchAvatarMovement((this.personData.uid), (data) => {
				this.animateFace(data);
			});
		} else {
			console.log('skipping cause its me')
		}

	}

	_loadGLTF = (url, cb) => {
		// load preset body
		let loader = new GLTFLoader();

		// Load a glTF resource
		loader.load(url, (gltf) => {

			gltf.scene.traverse( ( child ) => {
				// console.log("child name", child.name, child)
				// get bones
				if (child.name === "Hips") {
					this.hips = child;
					this.hips.initialPosition = new THREE.Vector3().copy(this.hips.position);
				} else if (child.name === "Spine") {
					this.spine = child;
				} else if (child.name === "Neck") {
					this.neck = child;
				} else if (child.name === "Head") {
					this.head = child;
				} else if (child.name === "head") {
					this.defaultHead = child;
				}

				if ( child.isMesh ) {
					// console.log("child name", child.name)

					child.castShadow = true;
					child.receiveShadow = true;

					child.material.metalness = 0;

					if (child.name === "shirt") {
						this.shirt = child;

						child.material = new THREE.MeshPhongMaterial({
							skinning: true,
							color: Utils.randomColor()
						});
					} else if (child.name === "hair") {
						const hairMaterial = new THREE.MeshStandardMaterial().copy( child.material );
						child.material = hairMaterial;

						this.hair = child;

						if (this.hairColor) {
							this.hair.material.map = null;
							this.hair.material.color.set( this.hairColor );
							this.hair.material.needsUpdate = true;
						}
					} else if (child.name === "face") {
					} else if (child.name === "eyeL" || child.name === "eyeR") {
						const eyeMaterial = new THREE.MeshStandardMaterial().copy( child.material );
						child.material = eyeMaterial;
					} else if (child.name === "body") {
						// child.material = new THREE.MeshNormalMaterial({
						this.skin = child;
						// console.log('skin color', child.material);

						if (this.skinColor) {
							// console.log('setting skinColor', this.skinColor, child)
							this.skin.material.map = null;
							this.skin.material.color.set( this.skinColor );
							this.skin.material.needsUpdate = true;
						}
					}
				}
			})

			this.gltf = gltf;
			this.playAnimations(this.gltf);

			gltf.scene.name = "object_scene";
			this.body = gltf.scene;

			// this.scene.add( this.body );
			cb(this.body);
		},
			// called while loading is progressing
			(xhr) => {
				// console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
				if (xhr.loaded === xhr.total) {
					console.log('done loading gltf')
				}
			},
			// called when loading has errors
			(error) => {
				console.log( 'An error happened', error );
			}
		);
	}

	playAnimations() {
		if (this.gltf) {
			// this.mixer = this.mixer || new THREE.AnimationMixer(this.gltf.scene);

			// // filter out hips
			// const valuesToRemove = ['Hips.position']
			// const filteredAnimations = this.gltf.animations[0].tracks.filter(item => !valuesToRemove.includes(item.name))

			// this.gltf.animations[0].tracks = filteredAnimations;
			// // console.log("animations", filteredAnimations, this.gltf.animations[0].tracks)

			// this.gltf.animations.forEach((clip) => {
			// 	this.mixer.clipAction(clip).play();
			// });
		}
	}

	animateFace(data) {

		if (this.body) {
			// console.log('animating face', data)
			// only render this group
			this.tweenGroup.update();

			const positionFactor = .2;

			// console.log(this.hips.initialPosition)
			// poor man's ik
			let body = {
				position: {
					x: data.global.position.x,
					y: data.global.position.y,
					z: data.global.position.z
				}, rotation: {
					x: data.global.rotation.x,
					y: data.global.rotation.y,
					z: data.global.rotation.z
				}
			};
			// console.log('animating body', data.global.position)

			let hips = {
				position: {
					x: (data.position.x * positionFactor),
					y: this.hips.initialPosition.y + (data.position.y * positionFactor),
					z: this.hips.initialPosition.z + data.position.z
				}, rotation: {
					x: 0,
					y: data.rotation.y/4,
					z: 0
				}
			};

			let spine = {
				rotation: {
					x: data.rotation.x/3,
					y: data.rotation.y/2,
					z: data.rotation.z/2
				}
			}

			let neck = {
				rotation: {
					x: data.rotation.x - Math.PI/16,
					y: data.rotation.y,
					z: data.rotation.z
				}
			}

			// remove tweens over a certain amount
			const allGroupTweens = this.tweenGroup.getAll().length;

			// console.log("all tweens", allGroupTweens);
			if (allGroupTweens > 8) {
				this.tweenGroup.removeAll();
				// console.log("remove tweens", allGroupTweens);
			}

			// don't spin
			const rotationYDelta = Math.abs(this.body.rotation.y - body.rotation.y);

			if (rotationYDelta > Math.PI) {
				let remappedRotationY = this.body.rotation.y > 0 ? this.body.rotation.y - (2*Math.PI) : (2*Math.PI) + this.body.rotation.y;
				// console.log('rotate', this.body.rotation.y, body.rotation.y, remappedRotationY)
				this.body.rotation.set(this.body.rotation.x, remappedRotationY, this.body.rotation.z)
			}

			// tween coordinates
			this._tweenPoint(this.body.position, body.position, 500);
			this._tweenPoint(this.body.rotation, body.rotation, 1000);


			this._tweenPoint(this.hips.position, hips.position);
			this._tweenPoint(this.hips.rotation, hips.rotation);

			this._tweenPoint(this.spine.rotation, spine.rotation);
			this._tweenPoint(this.neck.rotation, neck.rotation);

			// mouth detection
			let have_morph_targets = !!this.avatarHead && this.avatarHead.morphTargetInfluences && this.avatarHead.morphTargetInfluences.length > 0;

			if (have_morph_targets) {
				// this.avatarHead.morphTargetInfluences[1] = Math.max(data.expressions.sad, data.expressions.disgusted);

				// this.avatarHead.morphTargetInfluences[0] = data.blendshapes.jawOpen;

				// console.log(this.avatarHead.morphTargetInfluences[0]); // this is just a value
				// this.avatarHead.morphTargetInfluences[2] = Math.max(data.expressions.sad, data.expressions.disgusted);
				const eyeFactor = 2;
				let eyes;
				// console.log("mmouse",data.mousePosition)
				if (data.mousePosition) {
					eyes = {
						eyeLookUpLeft: Math.min(data.mousePosition.y, 0) * -1, // up
						eyeLookOutLeft: Math.max(data.mousePosition.x, 0) * 1, // out
						eyeLookDownLeft: Math.max(data.mousePosition.y, 0) * 1, // down
						eyeLookInLeft: Math.min(data.mousePosition.x, 0) * -1, // in
						eyeLookUpRight: Math.min(data.mousePosition.y, 0) * -1, // up
						eyeLookOutRight: Math.min(data.mousePosition.x, 0) * -1, // out
						eyeLookDownRight: Math.max(data.mousePosition.y, 0) * 1, // down
						eyeLookInRight: Math.max(data.mousePosition.x, 0) * 1 // in
					}
				} else {
					eyes = {
						eyeLookUpLeft: Math.min(data.rotation.x, 0) * -eyeFactor, // up
						eyeLookOutLeft: Math.max(data.rotation.y, 0) * eyeFactor, // out
						eyeLookDownLeft: Math.max(data.rotation.x, 0) * eyeFactor, // down
						eyeLookInLeft: Math.min(data.rotation.y, 0) * -eyeFactor, // in
						eyeLookUpRight: Math.min(data.rotation.x, 0) * -eyeFactor, // up
						eyeLookOutRight: Math.min(data.rotation.y, 0) * -eyeFactor, // out
						eyeLookDownRight: Math.max(data.rotation.x, 0) * eyeFactor, // down
						eyeLookInRight: Math.max(data.rotation.y, 0) * eyeFactor // in
					}
				}

				this._tweenBlendshape(this.avatarHead.morphTargetInfluences, eyes, tweenDuration);

				// console.log(data.blendshapes.jawOpen, data.expressions.surprised)
				let faceMorphs = {
					jawOpen: data.blendshapes.jawOpen,
					mouthSmileLeft: data.expressions.happy,
					mouthSmileRight: data.expressions.happy,
					mouthFrownLeft: data.expressions.sad,
					mouthFrownRight: data.expressions.sad,
					mouthLeft: data.blendshapes.mouthLeft,
					mouthRight: data.blendshapes.mouthRight,

					// mouthPucker: data.blendshapes.mouthPucker,
					browInnerUp: Math.max(data.expressions.suprised, data.expressions.happy)
				}

				this._tweenBlendshape(this.avatarHead.morphTargetInfluences, faceMorphs, tweenDuration);

				if (!this.eyeBlink) {
					this.eyeBlink = this._tweenRepeatBlendshape(this.avatarHead.morphTargetInfluences, "eyeBlinkLeft", 1, 200);
					this._tweenRepeatBlendshape(this.avatarHead.morphTargetInfluences, "eyeBlinkRight", 1, 200);
				}
			}

		}


	}

	getNeckPosition = () => {
		// console.log(this.neck.rotation)
		return this.neck.rotation
	}

	_tweenPoint = (target, endParams, duration=tweenDuration) => {
		// todo: garbage collect tweens

		new TWEEN.Tween( target, this.tweenGroup )
			.to( endParams, duration )
			.easing( TWEEN.Easing.Exponential.Out )
			.start();
	}

	_tweenBlendshape = (target, variables, endParams, duration=tweenDuration) => {
		Object.keys(variables).map( (key) => {
			// let blendshapeKey = AvatarSDK.blendshapes()[value];
			// console.log("hello", key, AvatarSDK.blendshapes()[key], variables[key]);
			return this._tweenVariable(target, AvatarSDK.blendshapes()[key], variables[key], duration);
		})
	}

	_tweenVariable = (target, variable, endParams, duration=tweenDuration) => {
		// todo: garbage collect tweens
		let tweenTo = {};
		tweenTo[variable] = endParams; // set the custom variable to the target

		return new TWEEN.Tween( target, this.tweenGroup )
			.to( tweenTo, duration )
			.easing( TWEEN.Easing.Exponential.Out )
			.start();
	}

	_tweenRepeatBlendshape = (target, variable, endParams, duration=tweenDuration) => {
		const mappedBlendshapeVariable = AvatarSDK.blendshapes()[variable];
		return this._tweenRepeatVariable(target, mappedBlendshapeVariable, endParams, duration);
	}

	_tweenRepeatVariable = (target, variable, endParams, duration=tweenDuration) => {
		// todo: garbage collect tweens
		let tweenTo = {};
		let tweenBack = {};

		tweenTo[variable] = endParams; // set the custom variable to the target
		tweenBack[variable] = 0; // set the custom variable to the target

		// don't add these to tween group, cause itll get cancelled
		let tweenA = new TWEEN.Tween( target )
			.to( tweenTo, duration )
			.delay(3500)
			.easing( TWEEN.Easing.Exponential.Out )
			.start()

		let tweenB = new TWEEN.Tween( target )
			.to( tweenBack, duration*2 )
			// .delay(0)
			.easing( TWEEN.Easing.Exponential.Out )

		tweenA.chain(tweenB);
		tweenB.chain(tweenA);

		return tweenA
	}

	updateAnimations(delta) {
				// only render this group
		if (this.tweenGroup) this.tweenGroup.update();
		if ( this.mixer ) this.mixer.update( delta );
	}

	_pulseObject = (object) => {
		// play morphs
		// if (this.defaultHead) {
		// 	this._pulseObject(this.defaultHead)
		// }
		object.traverse( child => {
			if (child.materials) {
				console.log(child, child.materials)
				// child.materials[0].transparent = true;
				// child.materials[0].opacity = 1 + Math.sin(new Date().getTime() * .0025);//or any other value you like
				// child.visible = false;
			}
			return
		});
	}

	_hideObject = (object) => {
		object.traverse( child => {
			// if (child instanceof THREE.Mesh) {
			if (child.isMesh) {
				child.visible = false;
			}
			return
		});
	}
}

export default SceneAvatar;